import {CalendarOutlined, StopOutlined} from "@ant-design/icons";
import { Button, Col, Divider, Form, Modal, notification, Row, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../../api/calls";
import ScheduleFirstStepMassEdit from "./ScheduleFirstStepMassEdit";
import ScheduleSecondStepMassEdit from "./ScheduleSecondStepMassEdit";
import ScheduleThirdStepMassEdit from "./ScheduleThirdStepMassEdit";
import ClientInformSelect from "../ClientInformSelect";

const ScheduleMultiStepMassEdit = ({ selectedBuildingUnits }) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const [isOpenStop, setOpenStop] = useState(false);
  const [current, setCurrent] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const [form] = Form.useForm();

  const steps = [
    {
      title: t("schedules.termination"),
      content: (
        <ScheduleFirstStepMassEdit
          form={form}
          moveToSecondStep={() => {
            setCurrent(isOpenStop ? 2 : 1);
          }}
        />
      ),
    },
    {
      title: t("schedules.newRules"),
      content: (
        <ScheduleSecondStepMassEdit
          form={form}
          selectedBuildingUnits={selectedBuildingUnits}
          moveToFirstStep={() => {
            setCurrent(0);
          }}
          moveToThirdStep={() => {
            setCurrent(2);
          }}
        />
      ),
    },
    {
      title: t("common.summary"),
      content: (
        <ScheduleThirdStepMassEdit
          form={form}
          selectedBuildingUnits={selectedBuildingUnits}
          submitting={submitting}
          moveToSecondStep={() => {
            setCurrent(isOpenStop ? 0 : 1);
          }}
          isOpenStop={isOpenStop}
        />
      ),
    },
  ];

  useEffect(() => {
    form.resetFields();
    setCurrent(0);
  }, [isOpen, form, isOpenStop]);

  return (
    <React.Fragment>
      <Button
        size={"small"}
        icon={<StopOutlined />}
        onClick={() => {
            setOpenStop(true);
            setOpen(true);
        }}
        style={{ marginRight: '15px' }}
      >
        {t("schedules.stopRules")}
      </Button>
      <Button
        size={"small"}
        icon={<CalendarOutlined />}
        onClick={() => {
          setOpen(true);
          setOpenStop(false);
        }}
        style={{ marginRight: '15px' }}
      >
        {t("schedules.rescheduleRules")}
      </Button>
      <ClientInformSelect values={selectedBuildingUnits}/>
      <Modal
        width={600}
        footer={null}
        title={t("schedules.rescheduleRules")}
        open={isOpen}
        onCancel={() => {
          setOpen(false);
          setOpenStop(false);
        }}
      >
        <Form
          form={form}
          // (values) as an argument not working here, the fields are not displayed in the summary
          onFinish={() => {
            setSubmitting(true);

            let values = form.getFieldsValue(true);
            values.date_terminate = values?.date_terminate?.format("YYYY-MM-DD") || null;
            values.building_units = selectedBuildingUnits.map((buildingUnit) => buildingUnit?.id);
            values.service_rrules = selectedBuildingUnits
              .map((buildingUnit) => {
                return buildingUnit.service_rrules.map((serviceRrule) => {
                  return serviceRrule.id;
                });
              })
              .flat();

            const reformattedData = {
              "date_terminate": values.date_terminate,
              "building_units": isOpenStop ? [] : values.building_units.map(unitId => ({
                "building_unit_id": unitId,
                "days": values[`building-${unitId}--days`],
                "date_from": values[`building-${unitId}--date_from`]?.format("YYYY-MM-DD"),
                "date_to": values[`building-${unitId}--date_to`]?.format("YYYY-MM-DD"),
                "service_ratio": values[`building-${unitId}--service_ratio`],
                "partner_id": values[`building-${unitId}--partner_id`]
              })),
              "service_rrules": values.service_rrules,
              "partnerek": values.partnerek,
              "selected_count": values.building_units.length
            };

            coreApi
              .post("/schedules/mass-create", reformattedData)
              .then((response) => {
                notification.success({
                  message: response.data.message,
                });
                setOpen(false);
                setOpenStop(false);
              })
              .catch((error) => {
                notification.error({ message: error?.response?.data?.message });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
                <Steps current={current}>
                    {steps.map((item, index) => {
                        if (isOpenStop && index === 1) {
                            return null;
                        }
                        return <Steps.Step key={index} title={item.title} />;
                    })}
                </Steps>
            </Col>
            <Divider />
            <Col span={24}>
              <div className="steps-content">{steps[current].content}</div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ScheduleMultiStepMassEdit;
