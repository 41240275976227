import { Col, DatePicker, Row, Tabs, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CountryReportTemplate from "../components/Reports/Country/CountryReportTemplate";
import PartnerClaimsReport from "../components/Reports/Country/PartnerClaimsReport";
import CustomTabs from "../components/custom/CustomTabs";

const CountryReports = (props) => {
  const { t } = useTranslation();

  const [selectedYear, setSelectedYear] = useState(moment());

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Col span={24}>
            <Typography.Text>{t("common.selectedPeriod")}: </Typography.Text>
            <DatePicker
              allowEmpty={false}
              allowClear={false}
              format={"YYYY"}
              picker={"year"}
              defaultValue={moment()}
              onChange={(value) => {
                setSelectedYear(value);
              }}
            ></DatePicker>
          </Col>
        </Col>
        <Col span={24}>
          <CustomTabs props={props} size={"large"} defaultActiveKey={"#ClientInvoices"}>
            <Tabs.TabPane key={"#ClientInvoices"} tab={t("reports.country.clientInvoices")}>
              <CountryReportTemplate
                year={selectedYear}
                APIurl={"/reports/country/client-invoices"}
                cityDataPropName={"city_invoices_sum"}
                countryDataPropName={"country_invoices_sum"}
                currencyValues={true}
              ></CountryReportTemplate>
            </Tabs.TabPane>
            <Tabs.TabPane key={"#AnnaPartnerInvoices"} tab={t("reports.country.annaPartnerInvoices")}>
              <CountryReportTemplate
                year={selectedYear}
                APIurl={"/reports/country/anna-partner-invoices"}
                cityDataPropName={"anna_partner_invoices_city"}
                countryDataPropName={"anna_partner_invoices_country"}
                currencyValues={true}
              ></CountryReportTemplate>
            </Tabs.TabPane>
            <Tabs.TabPane key={"#unpaidInvoices"} tab={t("reports.country.unpaidInvoices")}>
              <CountryReportTemplate
                year={selectedYear}
                APIurl={"/reports/country/unpaid-invoices"}
                cityDataPropName={"unpaid_invoices_city"}
                countryDataPropName={"unpaid_invoices_country"}
                currencyValues={true}
              ></CountryReportTemplate>
            </Tabs.TabPane>
            <Tabs.TabPane key={"#activeBuildings"} tab={t("reports.country.activeBuildings")}>
              <CountryReportTemplate
                year={selectedYear}
                APIurl={"/reports/country/active-buildings"}
                countryDataPropName={"active_buildings_count_country"}
                cityDataPropName={"active_buildings_count_city"}
              ></CountryReportTemplate>
            </Tabs.TabPane>
            <Tabs.TabPane key={"#cleanings"} tab={t("reports.country.cleanings")}>
              <CountryReportTemplate
                year={selectedYear}
                APIurl={"/reports/country/cleanings"}
                cityDataPropName={"cleanings_count_city"}
                countryDataPropName={"cleanings_count_country"}
              ></CountryReportTemplate>
            </Tabs.TabPane>
            <Tabs.TabPane key={"#partnersWithActiveBuildings"} tab={t("reports.country.partnersWithActiveBuildings")}>
              <CountryReportTemplate
                year={selectedYear}
                APIurl={"/reports/country/active-partners"}
                cityDataPropName={"active_partners_count_city"}
                countryDataPropName={"active_partners_count_country"}
              ></CountryReportTemplate>
            </Tabs.TabPane>
            <Tabs.TabPane key={"#partnersClaims"} tab={t("reports.country.partnersClaims")}>
              <PartnerClaimsReport year={selectedYear} />
            </Tabs.TabPane>
          </CustomTabs>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CountryReports;
