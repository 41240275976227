import React from "react";
import { useTranslation } from "react-i18next";
import { RRule, rrulestr } from "rrule";

const FrequencyCell = ({ rule }) => {
  const { t } = useTranslation();

  if (!rule) {
    return <React.Fragment></React.Fragment>;
  }

  let rrule = rrulestr(rule);

  let result =
    rrule.origOptions.byweekday.length +
    "x ";

  if (rrule.origOptions.interval > 1 && rrule.origOptions.interval < 5 && rrule.origOptions.freq === RRule.WEEKLY) {
    result =
      result + 7*rrule.origOptions.interval + " " +  t("rrules.freq.plDAILY");
  } else if (rrule.origOptions.interval > 1 && rrule.origOptions.interval < 5) {
    result =
      result +
      t("rrules.freq.pl" + RRule.FREQUENCIES[rrule.origOptions.freq]);
  } else if (rrule.origOptions.interval >= 5) {
    result =
      result +
      t("rrules.freq.mpl" + RRule.FREQUENCIES[rrule.origOptions.freq]);
  } else {
    result =
      result +
      t("rrules.freq." + RRule.FREQUENCIES[rrule.origOptions.freq]);
  }

  return <React.Fragment>{result}</React.Fragment>;
};

export default FrequencyCell;
