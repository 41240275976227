import { Button, Col, Modal, notification } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

/**
 * Single Partner Payment Pane
 * @component
 * @alias SinglePartnerPaymentPane
 * @property {string} dateFrom - start date for payments
 * @property {string} dateTo - end date for payments
 * @property {object} params - contains partner_id and other details
 * @property {function} onXmlUpdate - optional callback to update XML externally
 * @return
 */
const SinglePartnerPaymentPane = ({ dateFrom, dateTo, params, idCityBranch = null, onXmlUpdate = null }) => {
  const { t } = useTranslation();
  const [XML, setXML] = useState(null);
  const xmlRef = useRef();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const fetchXml = () => {
    coreApi
      .post("payments/single-partner-payment", {
        date_to: dateTo,
        date_from: dateFrom,
        partner_id: params?.id,
        city_branch_id: idCityBranch,
      })
      .then((res) => {
        setXML(res.data.xml);
        if (onXmlUpdate) {
          onXmlUpdate(res.data.xml);
        }
      })
      .catch((err) => {
        notification.error({
          message: t("error.title"),
          description: err.response?.data || t("error.generic"),
        });
      });
  };

  const showModal = () => {
    fetchXml();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCopy = () => {
    if (xmlRef.current) {
      navigator.clipboard.writeText(xmlRef.current.innerText);
      notification.success({ message: t("common.copySuccess") });
    }
  };

  return (
    <React.Fragment>
      <Button type="primary" size="small" onClick={showModal}>
        {t("finance.moneyReturn.XMLcode")}
      </Button>
      <Modal
        title={t("finance.moneyReturn.XMLcode")}
        open={isModalVisible}
        onOk={handleCopy}
        okText={t("common.copy")}
        onCancel={handleCancel}
        width={600}
      >
        {XML && (
          <Col span={24}>
            <pre ref={xmlRef} style={{ fontStyle: "italic" }}>{XML?.split("<Orders>")[1].split("</Orders>")[0]}</pre>
          </Col>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default SinglePartnerPaymentPane;
