import React, { useEffect, useState } from "react";
import { Card, Col, Row, DatePicker, notification, Form, Descriptions, Button, Table, Tooltip, Modal, Input } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { coreApi } from "../../api/calls";
import PartnerBalanceCitySelector from "../Partners/PartnerBalanceCitySelector";
import PartnerLink from "../Partners/PartnerLink";

const PaymentsForCheckingPane = () => {
  const { t } = useTranslation();

  const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'months').startOf('month').startOf('day'));
  const [dateTo, setDateTo] = useState(moment().subtract(1, 'months').endOf('month').endOf('day'));
  const [selectedCity, setSelectedCity] = useState(null);
  const [reports, setReports] = useState(null);
  const [partnerAmounts, setPartnerAmounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingReports, setLoadingReports] = useState(false);
  const [loadingPartnerAmounts, setLoadingPartnerAmounts] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionDate, setTransactionDate] = useState(moment().date(16));
  const [isSendPaymentForApprovalButtonEnabled, setIsSendPaymentForApprovalButtonEnabled] = useState(false);

  const [currencyIso4217, setCurrencyIso4217] = useState("CZK");
  const [modalLoading, setModalLoading] = useState(false);

  const cellStyle = {
    direction: "rtl",
    fontWeight: "bold",
    paddingRight: "10px",
  };

  const fetchData = () => {
    if (!selectedCity) return;

    setLoadingReports(true);
    coreApi
      .get("/reports/city/" + selectedCity + "/overview", {
        params: {
          date_from: dateFrom.format("YYYY-MM-DD"),
          date_to: dateTo.format("YYYY-MM-DD"),
        },
      })
      .then(({ data }) => {
        setReports(data);
        setCurrencyIso4217(data.currency);
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setLoadingReports(false);
      });
  };

  const fetchPartnerAmounts = () => {
    if (!selectedCity) return;

    setLoadingPartnerAmounts(true);
    coreApi
      .post("/payments/partner-amounts", {
        city_branch_id: selectedCity,
        date_from: dateFrom.format("YYYY-MM-DD"),
        date_to: dateTo.format("YYYY-MM-DD"),
      })
      .then(({ data }) => {
        setPartnerAmounts(data);

        const hasNullSentAt = data.some(item =>
          item.total_amount !== 0 &&
          (item?.partner_balance ? item.partner_balance.sent_at === null : true)
        );

        setIsSendPaymentForApprovalButtonEnabled(hasNullSentAt);
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setLoadingPartnerAmounts(false);
      });
  };

  const downloadCSV = () => {
    setLoading(true);
    coreApi.post("/payments/partner-amounts/csv", {
      city_branch_id: selectedCity,
      date_from: dateFrom.format("YYYY-MM-DD"),
      date_to: dateTo.format("YYYY-MM-DD"),
    })
      .then(response => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "partner_amounts.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    fetchPartnerAmounts();
  }, [selectedCity, dateFrom, dateTo]);

  const partnerColumns = [
    {
      title: t("finance.transactions.partner"),
      dataIndex: "partner",
      key: "partner",
      render: (partner) => <PartnerLink partner={partner} />,
    },
    {
      title: t("common.total"),
      dataIndex: "total_amount",
      key: "total_amount",
      render: (amount) => new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: currencyIso4217 || "CZK",
      }).format(amount),
    },
    {
      title: t("finance.transactions.sentAt"),
      dataIndex: "partner_balance",
      key: "partner_balance",
      render: (partner_balance) => partner_balance && partner_balance.sent_at
        ? moment(partner_balance.sent_at).format("DD.MM.YYYY HH:mm")
        : ''
    },
  ];

  const totalAmountSum = partnerAmounts.reduce((acc, partner) => acc + partner.total_amount, 0);

  const sendToApproval = () => {
    if (!transactionDate) {
      notification.error({
        message: t("Validation Error"),
        description: t("Please select a transaction date before submitting."),
      });
      return;
    }

    setModalLoading(true);
    const payload = {
      transaction_date: moment(transactionDate).format("YYYY-MM-DD"),
      date_from: moment(dateFrom).format("YYYY-MM-DD"),
      date_to: moment(dateTo).format("YYYY-MM-DD"),
      city_branch_id: selectedCity,
    };

    coreApi
      .post("/payments/save-partners-payment-to-approval-request", payload)
      .then((res) => {
        notification.success({ message: res.data.message });
        setIsModalOpen(false);
      })
      .catch((err) => {
        notification.error({ message: err?.response?.data?.message });
      }).finally(() => {
      fetchPartnerAmounts();
      setModalLoading(false);
    });
  };

  const getBankAccount = (partner) => {
    if (partner?.currency_iso_4217 === "EUR" && partner?.account_iban) {
      return partner.account_iban;
    }

    if (partner?.account_number) {
      return partner?.account_prefix
        ? `${partner.account_prefix}-${partner.account_number}/${partner.account_bank_code}`
        : `${partner.account_number}/${partner.account_bank_code}`;
    }

    return null;
  };

  const getNotFilledPartnerBankAccounts = () => {
    return partnerAmounts
      .map((item) => {
        const bankAccount = getBankAccount(item.partner);
        return {
          partner: item.partner,
          bankAccount,
        };
      })
      .filter(item => !item.bankAccount)
      .map(item => item.partner);
  };

  return (
    <div>
      <Row gutter={[16, 0]} style={{ marginBottom: 16 }}>
        <Col>
          <Form.Item label={t("finance.balances.period")}>
            <DatePicker.RangePicker
              defaultValue={[dateFrom, dateTo]}
              picker="month"
              format="MMMM Y"
              onChange={(selectedDates) => {
                setDateFrom(selectedDates[0].startOf('month').startOf('day'));
                setDateTo(selectedDates[1].endOf('month').endOf('day'));
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <PartnerBalanceCitySelector
            name={"city_branch_id"}
            label={t("expansion.cities")}
            required={true}
            showSearch={true}
            setValue={(value) => {
              setSelectedCity(value);
              setLoadingReports(true);
              setLoadingPartnerAmounts(true);
            }}
          />
        </Col>
      </Row>

      {reports && (
        <Card size={"small"} loading={loadingReports} >
          <Descriptions size={"small"} span={8}>
            <Descriptions.Item span={24} label={t("reports.city.totalPartnerReward")} contentStyle={cellStyle}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {new Intl.NumberFormat("cs-CZ", {
                  style: "currency",
                  currency: currencyIso4217 || "CZK",
                }).format(
                  +reports?.partner_client_invoices.sum_with_vat +
                  +reports?.partner_anna_invoices.sum_with_vat +
                  +reports?.corrective_documents.sum_with_vat -
                  +reports?.anna_partner_invoices.sum_with_vat
                )}
              </div>
            </Descriptions.Item>
          </Descriptions>
          <Button onClick={downloadCSV} type="primary" style={{ marginTop: "16px", marginRight: "8px" }} loading={loading}>
            {t("buildingSetupChanges.downloadCsv")}
          </Button>
          <Button
            onClick={() => setIsModalOpen(true)}
            type="primary"
            style={{ marginTop: "16px" }}
            disabled={!isSendPaymentForApprovalButtonEnabled}
          >
            {t("finance.moneyReturn.sendPaymentForApproval")}
          </Button>
          <Modal
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            title={t("buildings.moneyReturn")}
            okText={t("finance.moneyReturn.sendPaymentForApproval")}
            onOk={sendToApproval}
            okButtonProps={{ loading: modalLoading, disabled: getNotFilledPartnerBankAccounts().length > 0 }}
          >
          <Form layout="inline">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {getNotFilledPartnerBankAccounts().length > 0 && (
                  <Form.Item style={{ marginBottom: '16px' }}>
                        {t("finance.paymentsForApproval.fillPartnerAccounts") + ": "}
                        {getNotFilledPartnerBankAccounts()
                          .map((partner) => <PartnerLink key={partner.id} partner={partner} />)
                          .reduce((prev, curr) => [prev, ", ", curr])}
                  </Form.Item>
                  )}
                  <Form.Item
                    label={t("finance.moneyReturn.date")}
                    name="transaction_date"
                    required={true}
                    initialValue={transactionDate}
                  >
                    <DatePicker
                      allowClear={false}
                      onChange={(value) => setTransactionDate(value)}
                      style={{ width: "100%" }}
                      disabledDate={(current) => current && current < moment().startOf('day')}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>

        </Card>
      )}

      {partnerAmounts.length > 0 && (
        <Card size={"small"} loading={loadingPartnerAmounts}>
          <Table
            columns={partnerColumns}
            dataSource={partnerAmounts}
            rowKey="partner_id"
            pagination={false}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={1}>
                  <strong>{t("common.total")}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <strong>{new Intl.NumberFormat("cs-CZ", {
                    style: "currency",
                    currency: currencyIso4217 || "CZK",
                  }).format(totalAmountSum)}</strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Card>
      )}

    </div>
  );
};

export default PaymentsForCheckingPane;
