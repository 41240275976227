import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Modal, notification, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "react-slidedown/lib/slidedown.css";
import { coreApi } from "../../api/calls";
import ClaimFormFragment from "../Claims/ClaimFormFragment";

/**
 * Mass Claim Modal - used for setting claims on multiple cleanings
 * @component
 * @alias MassClaimModal
 * @property {array} selectedRows - array of cleaning ids
 * @property {string} size - form size
 * @property {string} layout - form layout
 * @property {function} setSelectedRows
 * @property {function} refresh
 * @returns <Button /> component that opens a <Modal /> which contains form for setting the claim tag
 */
const MassClaimModal = ({
  selectedRows,
  size,
  layout,
  setSelectedRows,
  refresh,
  cleaning = null
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <React.Fragment>
      <Button
        icon={<ExclamationCircleOutlined />}
        size={"small"}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t("cleanings.massClaim")}
      </Button>

      <Modal
        width={"650px"}
        open={isOpen}
        onCancel={() => {
          form.resetFields();
          setIsOpen(false);
        }}
        title={t("cleanings.massClaim")}
        onOk={() => {
          form.submit();
        }}
        okButtonProps={{ loading: loading }}
      >
        <Form
          size={size}
          layout={layout}
          form={form}
          onFinish={(values) => {
            setLoading(true);

            // sends a array of cleaning ids and claim tag to set
            coreApi
              .put("/cleanings/mass-edit/claims", {
                ...values,
                selectedRows: selectedRows,
              })
              .then((response) => {
                notification.success({
                  message: response.data.message,
                });

                setSelectedRows([]);
                setIsOpen(false);
                form.resetFields();
                refresh();
              })
              .catch((error) => {
                notification.error({
                  message: error.response.data.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        >
          <Row gutter={16}>
            <ClaimFormFragment form={form} cleaning={cleaning} />
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default MassClaimModal;
