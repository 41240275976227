import { Card, Col, Divider, Form, notification, Radio, Row, Table } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../../api/calls";
import PartnerLink from "../../Partners/PartnerLink";
import EmployeeLink from "../../Employees/EmployeeLink";
import { useSelector } from "react-redux";
import { getAreaScope } from "../../../actions";

const PartnerClaimsReport = ({ year }) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  // Get city id from global filter
  const areaScope = useSelector(getAreaScope);
  const selectedCity = areaScope.areaScope;

  const [filters, setFilters] = useState({active_partners_only: 1});

  const fetchData = useCallback(() => {
    if (!year) return;
    if (!selectedCity) {
      notification.info({ message: t("expansion.selectCity")})
      return;
    }

    const params = {
      year: year.format("YYYY-MM-DD"),
      city_branch_id: selectedCity || null,
      active_partners_only: filters?.active_partners_only,
    };

    setLoading(true);
    coreApi
      .get("/reports/country/partner-claims", {
        params: params,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => notification.error({ message: err?.response?.data?.message }))
      .finally(() => {
        setLoading(false);
      });

  }, [year, selectedCity, filters]);

  const getRowClassName = (record) => {
    let overLimit = false;

    for (let i = 1; i <= 12; i++) {
      const sum =
        Number(record[`claims_count_month_${i}`] || 0) +
        Number(record[`claims_count_month_${i + 1}`] || 0) +
        Number(record[`claims_count_month_${i + 2}`] || 0);

      if (sum > 3) {
        overLimit = true;
        break;
      }
    }

    return overLimit ? "ant-tag-red" : "";
  };

  useEffect(() => {
    fetchData()
  }, [year, selectedCity, filters]);

  const columns = [
    {
      title: t("cleanings.partner"),
      key: "partner",
      dataIndex: "partner",
      width: 170,
      fixed: "left",
      render: (partner) => <PartnerLink partner={partner} />,
    },
    {
      title: t("invoices.manager"),
      key: "responsibility",
      dataIndex: "responsibility",
      width: 170,
      fixed: "left",
      render: (responsibility) => responsibility ? <EmployeeLink employee={responsibility.employee} /> : "-",
    },
    ...Array.from({ length: 12 }, (_, i) => ({
      title: moment().month(i).format("MMMM"),
      key: `claims_count_month_${i + 1}`,
      render: (row) => row[`claims_count_month_${i + 1}`] || 0,
    })),
    {
      title: t("common.total"),
      key: "claims_total",
      fixed: "right",
      width: 65,
      render: (row) => row[`claims_total`] || 0,
    },
  ];

  const renderTableSummary = (pageData) => {
    if (pageData.length === 0) return null;

    const totalClaims = Array.from({ length: 12 }, (_, i) =>
      pageData.reduce((sum, row) => sum + Number(row[`claims_count_month_${i + 1}`] || 0), 0)
    );

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={2}>
          <strong>{t("common.total")}</strong>
        </Table.Summary.Cell>
        {totalClaims.map((sum, i) => (
          <Table.Summary.Cell key={i} index={i + 2}>
            <strong>{sum}</strong>
          </Table.Summary.Cell>
        ))}
        <Table.Summary.Cell index={14}>
          <strong>{pageData.reduce((sum, row) => sum + Number(row[`claims_total`] || 0), 0)}</strong>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card size="small" bodyStyle={{ padding: "10px" }}>
          <Form
            layout="inline"
            style={{ marginBottom: "16px" }}
            initialValues={{ active_partners_only: filters.active_partners_only }}
          >
            <Form.Item name="active_partners_only">
              <Radio.Group
                onChange={(e) => setFilters({ ...filters, active_partners_only: e.target.value })}
                value={filters.active_partners_only}
              >
                <Radio value={1}>{t("partners.activePartners")}</Radio>
                <Radio value={0}>{t("partners.allPartners")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>

          <Table
            loading={isLoading}
            size="small"
            dataSource={data}
            columns={columns}
            pagination={false}
            scroll={{ x: "80%" }}
            rowClassName={getRowClassName}
            summary={renderTableSummary}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default PartnerClaimsReport;