import { Form, notification, Select, Spin } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const BuildingSelect = ({
  label,
  name,
  initialValue,
  disabledOption,
  readOnly,
  required,
  validationMessage,
  size,
  setIdBuilding = null,
  onChange = null,
  cityBranchId = null
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const fetchData = useCallback(async (page = 1, searchValue = "") => {
    setLoading(true);

    try {
      let url = `/buildings/all?page=${page}${searchValue ? `&search=${searchValue}` : ""}`;
      if (cityBranchId) {
        url += `&cityBranchId=${cityBranchId}`;
      }

      const response = await coreApi.get(url);
      const newBuildings = page === 1 ? response.data : [...buildings, ...response.data];
      const uniqueBuildings = Array.from(new Set(newBuildings.map(b => JSON.stringify(b)))).map(s => JSON.parse(s));

      setBuildings(uniqueBuildings);
      setHasMore(response.data.length > 0);
      setPage(page + 1);
    } catch (error) {
      notification.error({ message: error?.response?.data?.message });
    } finally {
      setLoading(false);
    }
  }, [buildings, cityBranchId]);

  const handleOnScroll = (e) => {
    const container = e.target;
    const list = container.querySelector(".rc-virtual-list-holder-inner");
    const containerHeight = container.clientHeight;
    const containerScrollTop = container.scrollTop;
    const listScrollHeight = list.scrollHeight;
    const remainingScroll = listScrollHeight - (containerHeight + containerScrollTop);
    const threshold = 800;

    if (remainingScroll <= threshold && !loading && hasMore) {
      fetchData(page, searchValue);
    }
  };

  const handleChangeSearchValue = (value) => {
    setSearchValue(value);
    setBuildings([]);
    setPage(1);
    setHasMore(true);
    fetchData(1, value);
  };

  const handleOnClear = () => {
    setSearchValue("");
    setBuildings([]);
    setPage(1);
    setHasMore(true);
    fetchData(1, "");
  };

  useEffect(() => {
    setBuildings([]);
    setPage(1);
    setHasMore(true);
    fetchData(1, searchValue);
  }, [cityBranchId]);

  return (
    <Form.Item
      hasFeedback
      name={name}
      label={label}
      initialValue={initialValue}
      valuePropName={"value"}
      rules={[
        {
          required: required,
          message: validationMessage,
        },
      ]}
    >
      <Select
        size={size}
        allowClear
        disabled={readOnly}
        bordered={!readOnly}
        loading={loading}
        onClear={handleOnClear}
        virtual={false}
        onPopupScroll={handleOnScroll}
        showSearch={true}
        placeholder={t("buildings.selectBuilding")}
        onSearch={handleChangeSearchValue}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(e) => {
          onChange && onChange(buildings.find((item) => item.id === e));
        }}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {buildings.filter((building) => building.reference).map((building) => (
          <Select.Option
            key={building.id + building.reference?.replace(/\s/g, "") || ""}
            value={building.id}
            disabled={disabledOption && building.id === disabledOption}
          >
            {building.reference || `No Building Reference (${building.id})`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default BuildingSelect;
