import { DatePicker, Form, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import CleaningClaimStatusSelect from "../Cleanings/CleaningClaimStatusSelect";
import CleaningClaimTagSelect from "../Cleanings/CleaningClaimTagSelect";
import EmployeeSelect from "../Employees/EmployeeSelect";
import ContactSelect from "../Contacts/ContactSelect";

/**
 * Claim Form Fragment - used in cleanings detail view to be able to set claim
 * @component
 * @alias ClaimFormFragment
 * @property {object} cleaning - default null
 * @property {object} form - parent form object
 * @returns react Fragment containing inner parts of claim form
 */
const ClaimFormFragment = ({ cleaning = null, form }) => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  const [claimFieldsVisible, setClaimFieldsVisible] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      claim_tag_id: cleaning?.claim_tag_id,
      claim_description: cleaning?.claim_description,
      claim_solution: cleaning?.claim_solution,
      is_claim_in_solution: cleaning?.is_claim_in_solution,
      is_claim_solved: cleaning?.is_claim_solved,
      date_satisfaction_check: cleaning?.date_satisfaction_check ? moment(cleaning?.date_satisfaction_check) : null,
      responsibility_id: cleaning?.responsibility_id
    })
  }, [cleaning]);

  useEffect(() => {
    if (claimFieldsVisible) {
      form.setFieldsValue({
        responsibility_id: cleaning?.responsibility_id ?? JSON.parse(localStorage.getItem("user")).id
      });
    }
  }, [claimFieldsVisible, cleaning]);

  return (
    <React.Fragment>
      <CleaningClaimStatusSelect
        showClaimFields={() => {
          setClaimFieldsVisible(true);
        }}
        hideClaimFields={() => {
          setClaimFieldsVisible(false);
        }}
        form={form}
        cleaning={cleaning}
      />
      <SlideDown className={"my-dropdown-slidedown"} closed={!claimFieldsVisible}>
        <CleaningClaimTagSelect initialValue={cleaning ? cleaning.claim_tag_id : null} />
        <Form.Item
          name={"date_satisfaction_check"}
          label={t("cleanings.satisfactionCheckDate")}
          initialValue={cleaning?.date_satisfaction_check ? moment(cleaning?.date_satisfaction_check) : null}
        >
          <DatePicker></DatePicker>
        </Form.Item>
        <Form.Item
          name={"claim_description"}
          label={t("cleanings.claimDescription")}
          initialValue={cleaning?.claim_description}
        >
          <Input.TextArea autoSize></Input.TextArea>
        </Form.Item>
        <Form.Item name={"claim_solution"} label={t("cleanings.claimSolution")} initialValue={cleaning?.claim_solution}>
          <Input.TextArea autoSize></Input.TextArea>
        </Form.Item>
        <EmployeeSelect
          name={"responsibility_id"}
          label={t("cleanings.liabilityClaims")}
          initialValue={cleaning?.responsibility_id ?? JSON.parse(localStorage.getItem("user")).id}
          disabledOption={cleaning?.partner_id}
          placeholder={t("cleanings.selectUser")}
          activeUsers={true}
          required={claimFieldsVisible}
        />
        <ContactSelect
          id={cleaning?.building_unit?.building?.id ?? cleaning.building_id}
          initialValue={cleaning?.contact_id}
          label={t("emails.with_full")}
          required={claimFieldsVisible}
        />
      </SlideDown>
    </React.Fragment>
  );
};

export default ClaimFormFragment;
