import { Card, Col, Descriptions, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../../api/calls";

const CityOverviewReport = ({ city, currencyIso4217, dateFrom, dateTo }) => {
  const { t } = useTranslation();

  const [reports, setReports] = useState();

  const [loading, setLoading] = useState(false);

  const cellStyle = {
    direction: "rtl",
    fontWeight: "bold",
    paddingRight: "10px",
  };

  useEffect(() => {
    setLoading(true);
    coreApi
      .get("/reports/city/" + city?.id + "/overview", {
        params: {
          date_from: dateFrom ? dateFrom.format("YYYY-MM-DD") : null,
          date_to: dateTo ? dateTo.format("YYYY-MM-DD") : null,
        },
      })
      .then(({ data }) => {
        setReports(data);
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [city, dateFrom, dateTo]);

  useEffect(() => {
    console.log(reports);
  }, [reports]);

  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        <Col xs={12} md={8}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card size={"small"} title={t("reports.city.recurringCleanings")} loading={loading}>
                <Descriptions layout="horizontal" size={"small"}>
                  <Descriptions.Item
                    span={24}
                    label={t("reports.city.cleanings.cleaningsCount")}
                    contentStyle={cellStyle}
                  >
                    {reports?.recurring_cleanings.cleanings_count || "0"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={24}
                    label={t("reports.city.cleanings.cleaningsSum")}
                    contentStyle={cellStyle}
                  >
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.recurring_cleanings.cleanings_sum)}
                  </Descriptions.Item>
                  <Descriptions.Item span={24} label={t("reports.city.cleanings.promoSum")} contentStyle={cellStyle}>
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.recurring_cleanings.promo_sum)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={24}
                    label={t("reports.city.cleanings.sumAfterPromo")}
                    contentStyle={cellStyle}
                  >
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.recurring_cleanings.sum_after_promo)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={24}
                    label={t("reports.city.cleanings.partnerShare")}
                    contentStyle={cellStyle}
                  >
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.recurring_cleanings.partner_profit)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={24}
                    label={t("reports.city.cleanings.substituteShare")}
                    contentStyle={cellStyle}
                  >
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.recurring_cleanings.substitute_profit)}
                  </Descriptions.Item>
                  <Descriptions.Item span={24} label={t("reports.city.cleanings.annaShare")} contentStyle={cellStyle}>
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(Number(Math.abs(reports?.recurring_cleanings.anna_profit) * -1))}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
            <Col span={24}>
              <Card size={"small"} loading={loading} title={t("reports.city.oneTimeCleanings")}>
                <Descriptions size={"small"} span={8}>
                  <Descriptions.Item
                    span={24}
                    label={t("reports.city.cleanings.cleaningsCount")}
                    contentStyle={cellStyle}
                  >
                    {reports?.one_time_cleanings.cleanings_count || "0"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={24}
                    label={t("reports.city.cleanings.cleaningsSum")}
                    contentStyle={cellStyle}
                  >
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.one_time_cleanings.cleanings_sum)}
                  </Descriptions.Item>
                  <Descriptions.Item span={24} label={t("reports.city.cleanings.promoSum")} contentStyle={cellStyle}>
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.one_time_cleanings.promo_sum)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={24}
                    label={t("reports.city.cleanings.sumAfterPromo")}
                    contentStyle={cellStyle}
                  >
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.one_time_cleanings.sum_after_promo)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={24}
                    label={t("reports.city.cleanings.partnerShare")}
                    contentStyle={cellStyle}
                  >
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.one_time_cleanings.partner_profit)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={24}
                    label={t("reports.city.cleanings.substituteShare")}
                    contentStyle={cellStyle}
                  >
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.one_time_cleanings.substitute_profit)}
                  </Descriptions.Item>
                  <Descriptions.Item span={24} label={t("reports.city.cleanings.annaShare")} contentStyle={cellStyle}>
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(Number(Math.abs(reports?.one_time_cleanings.anna_profit) * -1))}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={8}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card size={"small"} loading={loading} title={t("finance.invoices.partnerClientInvoices")}>
                <Descriptions size={"small"} span={8}>
                  <Descriptions.Item span={24} label={t("reports.city.invoices.count")} contentStyle={cellStyle}>
                    {reports?.partner_client_invoices.count || "0"}
                  </Descriptions.Item>

                  <Descriptions.Item span={24} label={t("reports.city.invoices.recurring")} contentStyle={cellStyle}>
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.recurring_cleanings.cleanings_sum || "0")}
                  </Descriptions.Item>

                  <Descriptions.Item span={24} label={t("reports.city.invoices.oneTime")} contentStyle={cellStyle}>
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.one_time_cleanings.cleanings_sum || "0")}
                  </Descriptions.Item>

                  <Descriptions.Item span={24} label={t("reports.city.invoices.aggregate")} contentStyle={cellStyle}>
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(
                      (parseFloat(reports?.one_time_cleanings.cleanings_sum) || 0) +
                        (parseFloat(reports?.recurring_cleanings.cleanings_sum) || 0)
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
            <Col span={24}>
              <Card size={"small"} loading={loading} title={t("finance.invoices.annaPartnerInvoices")}>
                <Descriptions size={"small"} span={8}>
                  <Descriptions.Item span={24} label={t("reports.city.invoices.count")} contentStyle={cellStyle}>
                    {reports?.anna_partner_invoices.count || "0"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={24}
                    label={t("reports.city.invoices.sumWithoutVat")}
                    contentStyle={cellStyle}
                  >
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.anna_partner_invoices.sum_without_vat)}
                  </Descriptions.Item>
                  <Descriptions.Item span={24} label={t("reports.city.invoices.vat")} contentStyle={cellStyle}>
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.anna_partner_invoices.vat)}
                  </Descriptions.Item>
                  <Descriptions.Item span={24} label={t("reports.city.invoices.sumWithVat")} contentStyle={cellStyle}>
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.anna_partner_invoices.sum_with_vat)}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
            <Col span={24}>
              <Card size={"small"} loading={loading} title={t("finance.invoices.partnerPartnerInvoices")}>
                <Descriptions size={"small"} span={8}>
                  <Descriptions.Item span={24} label={t("reports.city.invoices.count")} contentStyle={cellStyle}>
                    {reports?.partner_partner_invoices.count || "0"}
                  </Descriptions.Item>
                  <Descriptions.Item span={24} label={t("reports.city.invoices.sum")} contentStyle={cellStyle}>
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.partner_partner_invoices.sum)}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
            <Col span={24}>
              <Card size={"small"} loading={loading} title={t("finance.invoices.correctiveDocuments")}>
                <Descriptions size={"small"} span={8}>
                  <Descriptions.Item span={24} label={t("reports.city.invoices.count")} contentStyle={cellStyle}>
                    {reports?.corrective_documents.count || "0"}
                  </Descriptions.Item>
                  <Descriptions.Item span={24} label={t("reports.city.invoices.sum")} contentStyle={cellStyle}>
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(Math.abs(reports?.corrective_documents.sum))}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
            <Col span={24}>
              <Card size={"small"} loading={loading} title={t("finance.invoices.partnerAnnaInvoices")}>
                <Descriptions size={"small"} span={8}>
                  <Descriptions.Item span={24} label={t("reports.city.invoices.count")} contentStyle={cellStyle}>
                    {reports?.partner_anna_invoices.count || "0"}
                  </Descriptions.Item>
                  <Descriptions.Item span={24} label={t("reports.city.invoices.sum")} contentStyle={cellStyle}>
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.partner_anna_invoices.sum)}
                  </Descriptions.Item>
                  <Descriptions.Item span={24} label={t("reports.city.totalPartnerReward")} contentStyle={cellStyle}>
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(
                      +reports?.partner_client_invoices.sum_with_vat +
                        +reports?.partner_anna_invoices.sum_with_vat +
                        +reports?.corrective_documents.sum_with_vat -
                        +reports?.anna_partner_invoices.sum_with_vat
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={8}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card size={"small"} loading={loading} title={t("reports.city.partners")}>
                <Descriptions size={"small"} span={8}>
                  <Descriptions.Item span={24} label={t("reports.city.partnersWithCleanings")} contentStyle={cellStyle}>
                    {reports?.partners_with_cleanings || "0"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={24}
                    label={t("reports.city.activePartnersWithoutBuildings")}
                    contentStyle={cellStyle}
                  >
                    {reports?.active_partners_without_buildings || "0"}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
            <Col span={24}>
              <Card size={"small"} loading={loading} title={t("reports.city.unpaidInvoices")}>
                <Descriptions size={"small"} span={8}>
                  <Descriptions.Item span={24} label={t("reports.city.actualMonth")} contentStyle={cellStyle}>
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.actual_month_unpaid_invoices)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={24}
                    label={t("reports.city.aggregate")}
                    contentStyle={cellStyle}
                  >
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(reports?.unpaid_invoices)}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CityOverviewReport;
