import {Col, Row, Table, Tag, notification, Button, Modal, Checkbox} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { coreApi } from "../../api/calls";
import { useCallback } from "react";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { INVOICE_TYPES } from '../../constants';

/**
 * Building Units Invoices Pane
 * @component
 * @alias BuildingInvoicePane
 * @property {array} invoices
 * @returns <Table />
 */
const BuildingInvoicePane = ({ contacts }) => {
  // Translations
  const { t } = useTranslation();

  // Id from URL
  const { id } = useParams();

  // States
  const [invoices, setInvoices] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  const [sorter, setSorter] = useState({});
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState(contacts?.map(contact => contact.id) || []);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    const filteredInvoices = selectedInvoices.filter(inv => inv.corrected_invoice_id);
    const combinedSelections = [...filteredInvoices, ...selectedRows.filter(row => !row.corrected_invoice_id)];
    setSelectedInvoices(combinedSelections);
  };

  const rowSelection = {
    selectedRowKeys: selectedInvoices.map(invoice => invoice.id),
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: ![
        INVOICE_TYPES.PARTNER_CLIENT,
        INVOICE_TYPES.CORRECTIVE_DOCUMENT
      ].includes(record.invoice_type),
      name: `invoice-${record.id}`,
    }),
  };

  const onNestedSelectChange = (selectedRowKeys, selectedRows, parentRow) => {
    // Remove selections related to this parentRow
    const filteredInvoices = selectedInvoices.filter(
      inv => inv.corrected_invoice_id !== parentRow.id
    );
    // Append new selections with the parent id linked
    const updatedSelections = selectedRows.map(row => ({
      ...row,
      corrected_invoice_id: parentRow.id
    }));
    setSelectedInvoices([...filteredInvoices, ...updatedSelections]);
  };

  const nestedRowSelection = (parentRow) => ({
    selectedRowKeys: selectedInvoices
      .filter(invoice => invoice.corrected_invoice_id === parentRow.id)
      .map(invoice => invoice.id),
    onChange: (selectedRowKeys, selectedRows) => onNestedSelectChange(selectedRowKeys, selectedRows, parentRow),
  });

  const expandedRowRender = (row) => (
    <React.Fragment key={row.invoice_number}>
      <Table
        size="small"
        dataSource={row.corrective_documents.map(doc => ({
          ...doc,
          corrected_invoice_id: row.id
        }))}
        rowSelection={nestedRowSelection(row)}
        title={() => t("finance.invoices.correctiveDocument")}
        bordered={true}
        columns={invoiceTableColumns}
        showHeader={false}
        footer={false}
        rowKey="id"
        pagination={false}
      />
      <br></br>
    </React.Fragment>
  );

  function sendSelectedInvoices() {
    if (selectedInvoices.length > 0) {
      setDataLoading(true);

      const invoiceData = selectedInvoices.map(invoice => invoice.invoice_number);

      coreApi
        .post("invoices/send-selected", {invoice_numbers: invoiceData, contacts: selectedContacts})
        .then((response) => {
          notification.success({ message: response.data.message });
          fetchData();
        })
        .catch((err) => notification.error({ message: err.response.data.message }))
        .finally(() => {
          setDataLoading(false);
          setShowConfirmModal(false);
          setSelectedContacts(contacts.map(contact => contact.id));
        });
    } else {
      notification.error({ message: t("finance.invoices.noInvoicesSelected") });
    }
  }

  // Values for filtering
  const [filters, setFilters] = useState({
    date_issued_from: null,
    date_issued_to: null,
    invoice_type: null,
    page: 1,
  });

  // Fetch data from API
  const fetchData = useCallback(() => {
    setDataLoading(true);

    coreApi
      .get("buildings/invoices/" + id, {
        params: {
          ...filters,
          sorterOrder: sorter?.order,
          sorterKey: sorter?.columnKey,
          date_issued_from: filters?.date_issued_from?.format("YYYY-MM-DD"),
          date_issued_to: filters?.date_issued_to?.format("YYYY-MM-DD"),
          invoice_type: filters?.invoice_type,
          building_id: id,
          page_size: pagination?.pageSize || 10,
          page: pagination?.current,
        },
      })
      .then((response) => {
        let { data, ...pagination } = response.data;

        setPagination(pagination);
        setInvoices(data);
      })
      .catch((err) => notification.error({ message: err.response.data.message }))
      .finally(() => setDataLoading(false));
  }, [filters, id, pagination, sorter]);

  useEffect(() => {
    fetchData();
  }, [filters]);

  /**
   * Handle change of the table (e. g. sorting trigger)
   */
  const handleTableChange = (pagination, tableFilters, sorter) => {
    setFilters({ ...filters, pageSize: pagination?.pageSize, page: pagination?.current });

    setSorter(sorter);
    setPagination(pagination);
  };

  const handleContactChange = (contact, checked) => {
    setSelectedContacts(prevContacts =>
      checked ? [...prevContacts, contact] : prevContacts.filter(c => c !== contact)
    );
  };

  useEffect(() => {
    if (contacts && contacts.length > 0) {
      setSelectedContacts(contacts.map(contact => contact.id));
    } else {
      setSelectedContacts([]);
    }
  }, [contacts]);

  /**
   * Columns of the table
   */
  const invoiceTableColumns = [
    {
      title: t("finance.balances.period"),
      key: "date_issued",
      dataIndex: "date_issued",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("YYYY") + "/" + moment(rowData).format("MM");
      },
    },
    {
      title: t("finance.transactions.transactionNumber"),
      key: "id",
      render: (rowData) => {
        return (
          rowData?.transactions && (
            <Link style={{ borderBottom: "1px dotted" }} to={"/transactions/" + rowData?.transactions[0]?.id}>
              {rowData?.transactions[0]?.id || rowData?.transactions[0]?.reference}
            </Link>
          )
        );
      },
    },
    {
      title: t("finance.invoices.invoiceNumber"),
      key: "invoice_number",
      sorter: true,
      render: (rowData) => {
        return (
          <Link style={{ fontWeight: 500 }} to={"/invoices/" + rowData?.id}>
            {rowData?.invoice_number}
          </Link>
        );
      },
    },
    {
      title: t("finance.invoices.recipientName"),
      key: "recipient_name",
      dataIndex: "recipient_name",
      sorter: true,
    },
    {
      title: t("finance.invoices.recipientAddress"),
      key: "recipient_address",
      dataIndex: "recipient_address",
      sorter: true,
    },
    {
      title: t("finance.invoices.publisher"),
      key: "invoice_publisher_id",
      sorter: true,
      render: (rowData) => {
        return (
          rowData?.publisher && (
            <Link to={"/partners/" + rowData?.publisher?.id} style={{ borderBottom: "1px dotted" }}>
              {`${rowData?.publisher?.first_name} ${rowData?.publisher?.last_name}`}
            </Link>
          )
        );
      },
    },
    {
      title: t("finance.invoices.dateIssued"),
      key: "date_issued",
      dataIndex: "date_issued",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("DD.MM.YYYY");
      },
    },
    {
      title: t("finance.invoices.dateDue"),
      key: "date_due",
      dataIndex: "date_due",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("DD.MM.YYYY");
      },
    },
    {
      title: t("finance.invoices.totalAmount"),
      key: "total_amount",
      sorter: true,
      render: (rowData) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: rowData.currency_iso_4217 || "CZK",
        }).format(rowData.total_amount);
      },
    },
    {
      title: t("finance.invoices.syncDate"),
      key: "date_paind",
      dataIndex: "date_paid",
      sorter: true,
      render: (rowData) => {
        return rowData ? (
          <Tag color="green">{moment(rowData).format("DD.MM.YYYY")}</Tag>
        ) : (
          <Tag color="red">{t("finance.invoices.unpaid")}</Tag>
        );
      },
    },
    {
      title: t("finance.invoices.sentDate"),
      key: "date_sent_to_recipient",
      dataIndex: "date_sent_to_recipient",
      sorter: true,
      render: (rowData) => {
        return rowData ? (
          <Tag color="green">{moment(rowData).format("DD.MM.YYYY")}</Tag>
        ) : (
          <Tag></Tag>
        );
      },
    },
  ];

  return (
    <React.Fragment key="building_invoice_pane">
      <Row justify="end" style={{ marginBottom: 16 }}>
        <Col>
          <Button onClick={() => setShowConfirmModal(true)} disabled={!selectedInvoices.length}>{t("finance.invoices.sendSelectedInvoices")}</Button>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        size="small"
        loading={dataLoading}
        dataSource={invoices}
        columns={invoiceTableColumns}
        rowKey={"id"}
        onChange={handleTableChange}
        pagination={{
          ...pagination,
          showSizeChanger: true,
        }}
        expandable={{
          expandedRowRender,
          expandIcon: ({ expanded, onExpand, record, expandable }) =>
            expandable &&
            (expanded ? (
              <DownOutlined onClick={(e) => onExpand(record, e)} /> // close
            ) : (
              <RightOutlined onClick={(e) => onExpand(record, e)} /> // open
            )),

          rowExpandable: (row) => (!!row?.corrective_document),
        }}
      ></Table>
      
      {showConfirmModal && (
        <Modal
          title={t("finance.invoices.confirmSendingInvoices")}
          open={showConfirmModal}
          onOk={sendSelectedInvoices}
          onCancel={() => setShowConfirmModal(false)}
          okButtonProps={{ loading: dataLoading }}
          style={{minWidth: '580px'}}
        >
          <p>{t("finance.invoices.sureSendingInvoices")}</p>
          <ul>
            {selectedInvoices.map(invoice => (
              <li key={invoice.id}>
                {invoice.invoice_number} - {moment(invoice.date_issued).format("MM/YYYY")}
              </li>
            ))}
          </ul>
          {contacts.map(contact => (
            <label key={contact.id} style={{ display: 'block', marginBottom: '8px' }}>
              <Checkbox
                value={contact.id}
                checked={selectedContacts.includes(contact.id)}
                onChange={(e) => handleContactChange(contact.id, e.target.checked)}
              >
                {`${contact.first_name} ${contact.last_name} - ${contact.email} `}
                {[...new Map(
                  contact.tags
                    ?.filter((tag) => tag?.contactable_type === "building")
                    .map((tag) => [tag?.tag?.translation?.tag_name, tag])
                ).values()]
                  .map((tag) => (
                    <Tag
                      key={tag?.id}
                      color={t(`contacts.tagColors.${tag?.tag?.key}`)}
                      style={{ textAlign: "center" }}
                    >
                      {tag?.tag?.translation?.tag_name}
                    </Tag>
                  ))}
              </Checkbox>
            </label>
          ))}
        </Modal>
      )}
    </React.Fragment>
  );
};

export default BuildingInvoicePane;
