import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, notification,
  Row, Space, Tabs, Tooltip, Typography
} from "antd";
import { FormOutlined, EyeOutlined, HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import fileDownload from "js-file-download";

import { coreApi } from "../api/calls";
import BuildingUnitDetails from "../components/Buildings/BuildingUnitDetails";
import BuildingUnitSchedulerPane from "../components/Buildings/BuildingUnitSchedulerPane";
import ClaimsPane from "../components/Claims/ClaimsPane";
import CleaningListComplex from "../components/Cleanings/CleaningListComplex";
import ContactsPane from "../components/ContactsPane";
import CustomTabs from "../components/custom/CustomTabs";

const { Title } = Typography;

/**
 * Page with building unit detail
 * @component
 * @alias BuildingUnitProfile
 * @returns <Card /> with content
 */
const BuildingUnitProfile = (props) => {
  const { t } = useTranslation();
  const [buildingUnit, setBuildingUnit] = useState({});
  const [currentTabKey, setCurrentTabKey] = useState('');
  const [rulesForm] = Form.useForm();
  const [isDownloading, setDownloading] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rules, setRules] = useState([]);

  useEffect(() => {
    coreApi.get("buildings/units/" + props.match.params.id)
      .then((res) => setBuildingUnit(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [props.match.params.id]);

  useEffect(() => {
    rulesForm.resetFields();
  }, [rules]);

  const showModal = () => {
    setIsModalVisible(true);

    const getDefaultRule = () => [{
      name: "1x WEEKLY",
      date_from: moment(),
      rrule: "WEEKLY"
    }];

    coreApi
      .get("buildings/units/groups/" + props.match.params.id)
      .then((response) => {

        const extractWeeklyRules = (data) => {
          return data.filter(rule => rule.rrule_blueprint.includes("WEEKLY"))
            .slice(0, 1)
            .map(rule => ({
              ...rule,
              rrule: rule.rrule_blueprint,
              date_from: moment().day(moment().day() >= 1 ? 1 : -6)
            }));
        };

        const weeklyRules = extractWeeklyRules(response.data);

        setRules(weeklyRules.length > 0 ? weeklyRules : getDefaultRule());
      })
      .catch((error) => { console.error('Failed to fetch building unit groups:', error); })
      .finally(() => setLoading(false));
  };

  const onFinish = (values) => {
    const { rulesList, freeGeneralCleaning } = values;
    const dateFrom = rulesList[0]?.date_from.format("YYYY-MM-DD") || moment().format("YYYY-MM-DD");
    setDownloading(true);

    coreApi.get("/cleaning-schedule", {
      responseType: "blob",
      params: {
        building_unit_id: buildingUnit?.id,
        cleaning_date_start: dateFrom,
        free_general_cleaning: freeGeneralCleaning,
      },
    }).then((res) => {
      fileDownload(res.data, "rozpis-" + buildingUnit.street.toLowerCase() + "-" + buildingUnit.house_number + ".pdf");
    }).catch((err) => {
      notification.error({ message: err?.response?.data?.message });
    }).finally(() => {
      setDownloading(false);
      setIsModalVisible(false);
      rulesForm.resetFields();
    });
  };

  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Card key={buildingUnit?.id} loading={isLoading}>
            <Row gutter={[0, 24]} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <Title>
                  {buildingUnit?.building?.registration_number + " - " + buildingUnit.street + " " + buildingUnit.house_number}
                </Title>
                <Space>
                  <Typography.Title level={5} type="secondary" style={{ color: "#1890ff", fontWeight: 400 }}>
                    <Space size={[6, 12]}>
                      <Link to={`/buildings/${buildingUnit?.building_id}`}>
                        <HomeOutlined style={{ marginRight: "6px" }} />
                        {buildingUnit?.building?.reference}
                      </Link>
                    </Space>
                  </Typography.Title>
                </Space>
              </div>
              <div style={{ display: "flex", gap: "6px" }}>
                <Button type="primary" icon={<FormOutlined />} onClick={showModal} loading={isDownloading}>
                  {t("schedules.downloadPDFSchedule")}
                </Button>
                <Modal
                  title={t("schedules.downloadPDFSchedule")}
                  open={isModalVisible}
                  width={600}
                  onOk={rulesForm.submit}
                  onCancel={() => setIsModalVisible(false)}
                  okButtonProps={{ loading: isDownloading }}
                >
                  <Form
                    form={rulesForm}
                    onFinish={onFinish}
                    initialValues={{ rulesList: rules, freeGeneralCleaning: false }}
                  >
                    <Row gutter={[6, 6]}>
                      <Col span={24}>
                        <Row gutter={[12, 12]}>
                          <Col span={11}>
                            <strong>{t("schedules.frequancy")}:</strong>
                          </Col>
                          <Col span={11}>
                            <strong>{t("schedules.firstCleaning")}:</strong>
                          </Col>
                          <Col span={2}>
                            <Tooltip placement="top" title={t("schedules.include")}>
                              <EyeOutlined style={{ fontSize: "18px" }} />
                            </Tooltip>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Form.List name="rulesList">
                          {(fields) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <Row gutter={[12, 12]} key={key}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "cleaning_service_group_id"]}
                                    hidden
                                  >
                                    <Input />
                                  </Form.Item>
                                  <Col span={11}>
                                    <Form.Item {...restField} name={[name, "name"]}>
                                      <Input placeholder={t("schedules.frequancy")} disabled />
                                    </Form.Item>
                                  </Col>
                                  <Col span={11}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "date_from"]}
                                    >
                                      <DatePicker
                                        format="YYYY-MM-DD"
                                        value={rulesForm.getFieldValue(['rulesList', restField.fieldKey, 'date_from']) || moment()}
                                        placeholder={t("schedules.firstCleaning")}
                                        style={{ width: "100%" }}
                                        allowClear={false}
                                        picker="date"
                                        onChange={(value) => {
                                          const fields = rulesForm.getFieldsValue();
                                          const { rulesList } = fields;
                                          rulesList[restField.fieldKey].date_from = moment(value);
                                          rulesForm.setFieldsValue({ rulesList });
                                        }}
                                        disabledDate={(current) => {
                                          return current.day() !== 1;
                                        }}
                                        defaultPickerValue={moment()}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "include"]}
                                      initialValue={true}
                                      valuePropName="checked"
                                    >
                                      <Checkbox />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              ))}
                            </>
                          )}
                        </Form.List>
                        <Row gutter={[12, 12]}>
                          <Col span={11}></Col>
                          <Col span={11}>{t("schedules.freeGeneralCleaning")}</Col>
                          <Col span={2}>
                            <Form.Item name="freeGeneralCleaning" valuePropName="checked">
                              <Checkbox />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </Modal>
              </div>
            </Row>

            <CustomTabs props={props} onChange={(e) => { setCurrentTabKey(e) }}>
              <Tabs.TabPane tab={t("buildingUnits.overview")} key="#overview">
                <BuildingUnitDetails
                  buildingUnit={buildingUnit}
                  setBuildingUnit={setBuildingUnit}
                ></BuildingUnitDetails>
              </Tabs.TabPane>

              <Tabs.TabPane tab={t("buildingUnits.contacts")} key="#contacts">
                <ContactsPane
                  props={props}
                  contacts={buildingUnit?.contacts}
                  contactable={{ id: buildingUnit?.id, type: "buildingUnit" }}
                ></ContactsPane>
              </Tabs.TabPane>

              <Tabs.TabPane tab={t("schedules.schedule")} key="#schedule">
                <BuildingUnitSchedulerPane buildingUnit={buildingUnit} />
              </Tabs.TabPane>

              <Tabs.TabPane tab={t("cleanings.cleanings")} key="#cleanings">
                <CleaningListComplex buildingUnit={buildingUnit} />
              </Tabs.TabPane>

              <Tabs.TabPane tab={t("buildingUnits.claims")} key="#claims">
                <ClaimsPane buildingUnit={buildingUnit} currentTabKey={currentTabKey} />
              </Tabs.TabPane>
            </CustomTabs>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BuildingUnitProfile;
