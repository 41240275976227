import { ArrowRightOutlined, EditOutlined, RetweetOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Form, notification, Radio, Row, Space, Table, Tag, Tooltip } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SlideDown from "react-slidedown";
import { getAreaScope } from "../../actions";
import { coreApi } from "../../api/calls";
import BuildingUnitSelect from "../Buildings/BuildingUnitSelect";
import PartnerSelect from "../Partners/PartnerSelect";
import ManagerSelect from "./../ManagerSelect";
import CleaningEditModal from "./CleaningEditModal";
import MassClaimModal from "./MassClaimModal";
import MassPromoModal from "./MassPromoModal";
import MassSubstituteModal from "./MassSubstituteModal";
import CleaningClaimStatusCircle from "./CleaningClaimStatusCircle";
import BuildingSelect from "../Buildings/BuildingSelect";

/**
 * Cleaning List
 * @component
 * @alias CleaningList
 * @returns <React.Fragment /> containing <Table /> that has all the cleanings with ability to filter data
 */
const CleaningsList = () => {
  const { t } = useTranslation();

  // Get city id from global filter
  const areaScope = useSelector(getAreaScope);
  const selectedCity = areaScope.areaScope;

  // Create form for filtering
  const [filterForm] = Form.useForm();

  // States
  const [isLoading, setLoading] = useState(false);
  const [cleanings, setCleanings] = useState([]);
  const [pagination, setPagination] = useState({});
  const [selectedCleaning, setSelectedCleaning] = useState();
  const [cleaning, setCleaning] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  // Default filters values
  let defaultFilters = {
    date_from: moment().startOf("month"),
    date_to: moment().endOf("month"),
    page: 1,
    page_size: 10,
    partner_id: null,
    building_unit_id: null,
    manager_id: null,
    is_promo: false,
    is_claim: false,
    is_recurring: 1,
    city_branch_id: selectedCity,
    filters: filterForm.getFieldValue("partner_id"),
  };

  const [filters, setFilters] = useState(defaultFilters);

  // When filters change, re-renders changes
  const handleTableChange = useCallback(
    (pagination) => {
      setLoading(true);

      // Get cleanings by filters
      axios
        .all([
          coreApi.get("/cleanings", {
            params: {
              ...filters,
              city_branch_id: selectedCity,
              date_from: filters?.date_from.format("YYYY-MM-DD"),
              date_to: filters?.date_to.format("YYYY-MM-DD"),
            },
          }),
        ])
        .then(
          axios.spread((cleanings) => {
            let { data, ...pagination } = cleanings.data;

            setPagination(pagination);
            setCleanings(data);
          })
        )
        .catch((err) => notification.error({ message: err.response.data.message }))
        .finally(() => setLoading(false));
    },
    [filters, selectedCity]
  );

  useEffect(() => {
    handleTableChange();
  }, [filters, handleTableChange]);

  // Columns of table
  const tableColumns = [
    {
      title: t("cleanings.dateExpected"),
      key: "date_expected",
      dataIndex: "date_expected",
      width: 100,
      sorter: (a, b) => moment(a.date_expected).unix() - moment(b.date_expected).unix(),
      render: (date) => moment(date).format("L"),
    },
    {
      width: "110px",
      title: t("cleanings.registrationNumber"),
      key: "registration_number",
      render: (cleaning) => (
        <Tooltip title={cleaning?.building_registration_number}>
          <Link to={"/buildings/" + cleaning?.building_id} target={"_blank"}>
            <span>{cleaning?.building_registration_number}</span>
          </Link>
        </Tooltip>
      ),
    },
    {
      title: t("schedules.buildingUnit"),
      key: "building_unit",
      width: "220px",
      sorter: (a, b) => a?.building_unit_street.localeCompare(b?.building_unit_street),
      render: (cleaning) => (
        <Tooltip title={cleaning?.building_unit_street + " " + cleaning?.building_unit_house_number}>
          <Link
            to={"/buildings/" + cleaning?.building_id + "/unit/" + cleaning?.building_unit_id}
            style={{ borderBottom: "1px dotted" }}
          >
            {cleaning?.building_unit_street + " " + cleaning?.building_unit_house_number}
          </Link>
        </Tooltip>
      ),
      filters: null,
      filterMultiple: true,
      onFilter: (value, record) => record.address.indexOf(value) === 0,
    },
    {
      title: t("cleanings.fullName"),
      key: "company_name",
      width: "160px",
      sorter: (a, b) =>
        a.partner_last_name
          ? a.partner_last_name.localeCompare(b.partner_last_name)
          : a.partner_company_name.localeCompare(b.partner_company_name),
      render: (cleaning) => (
        <Tooltip
          title={cleaning?.partner_first_name + " " + cleaning?.partner_last_name || cleaning?.partner_company_name}
        >
          <Link to={"/partners/" + cleaning?.partner_id} style={{ borderBottom: "1px dotted" }}>
            {cleaning?.partner_first_name + " " + cleaning?.partner_last_name || cleaning?.partner_company_name}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: t("cleanings.substitute"),
      key: "substitute",
      width: 100,
      sorter: (a, b) => {
        const lastNameA = a.substitute_last_name || "";
        const lastNameB = b.substitute_last_name || "";
        const companyNameA = a.substitute_company_name || "";
        const companyNameB = b.substitute_company_name || "";

        if (lastNameA && lastNameB) {
          return lastNameA.localeCompare(lastNameB);
        } else if (lastNameA) {
          return -1; // a comes before b if only a has last name
        } else if (lastNameB) {
          return 1; // b comes before a if only b has last name
        } else {
          return companyNameA.localeCompare(companyNameB);
        }
      },
      render: (cleaning) => {
        return (
          cleaning?.substitute_id && (
            <Tooltip
              title={
                cleaning?.substitute_first_name + " " + cleaning?.substitute_last_name ||
                cleaning?.substitute_company_name
              }
            >
              <Link to={"/partners/" + cleaning?.substitute_id} style={{ borderBottom: "1px dotted" }}>
                {cleaning?.substitute_first_name + " " + cleaning?.substitute_last_name ||
                  cleaning?.substitute_company_name}
              </Link>
            </Tooltip>
          )
        );
      },
    },
    {
      title: t("cleanings.price"),
      key: "price",
      width: "60px",
      sorter: (a, b) => a.price - b.price,
      render: (cleaning) => {
        return parseFloat(cleaning.price).toFixed(2);
      },
    },
    {
      title: t("cleanings.promo"),
      key: "promo",
      width: "60px",
      sorter: (a, b) => a.promo - b.promo,
      render: (cleaning) => {
        return parseFloat(cleaning.promo).toFixed(2);
      },
    },
    {
      title: t("cleanings.partnerProfit"),
      key: "partner_profit",
      width: "60px",
      sorter: (a, b) => a.partner_profit - b.partner_profit,
      render: (cleaning) => {
        return parseFloat(cleaning.partner_profit).toFixed(2);
      },
    },
    {
      title: t("cleanings.annaProfit"),
      key: "anna_profit",
      width: "60px",
      sorter: (a, b) => a.anna_profit - b.anna_profit,
      render: (cleaning) => {
        return parseFloat(cleaning.anna_profit).toFixed(2);
      },
    },
    {
      title: t("cleanings.substituteProfit"),
      key: "substitute_profit",
      width: "60px",
      sorter: (a, b) => a.substitute_profit - b.substitute_profit,
      render: (cleaning) => {
        return parseFloat(cleaning.substitute_profit).toFixed(2);
      },
    },
    {
      title: t("cleanings.claimStatusShort"),
      key: "claim_status",
      render: (cleaning) => <CleaningClaimStatusCircle cleaning={cleaning} />,
      width: "55px",
      filters: [
        {
          text: t("cleanings.claimInSolution"),
          value: "is_claim_in_solution",
        },
        {
          text: t("cleanings.claimSolved"),
          value: "is_claim_solved",
        },
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        return Boolean(record[value]) === true;
      },
    },
    {
      title: t("cleanings.type"),
      key: "is_recurring",
      dataIndex: "is_recurring",
      width: "7%",
      sorter: (a, b) => a.is_recurring - b.is_recurring,
      render: (is_recurring) => (
        <React.Fragment>
          {is_recurring === 0 && (
            <Tag icon={<ArrowRightOutlined />} color="blue" style={{ fontSize: "9px" }}>
              Jednorázový
            </Tag>
          )}
          {is_recurring === 1 && (
            <Tag icon={<RetweetOutlined />} color="blue" style={{ fontSize: "9px" }}>
              Pravidelný
            </Tag>
          )}
        </React.Fragment>
      ),
    },
    {
      title: t("common.actions"),
      key: "actions",
      width: "5%",
      render: (cleaning) => (
        <Button
          size={"small"}
          icon={<EditOutlined />}
          onClick={() => {
            setSelectedCleaning(cleaning);
            setEditModalOpen(true);
          }}
        ></Button>
      ),
    },
  ];

  return (
    <React.Fragment>
      {/* Filters */}
      <Row>
        <Col span={24}>
          <Form
            layout={"inline"}
            form={filterForm}
            className="filterForm"
            style={{ dispay: "flex", gap: "12px", paddingBottom: "20px" }}
          >
            {/* Select filter with partners */}
            <Col span={8}>
              <PartnerSelect
                name={"partner_id"}
                label={false}
                validationMessage={t("cleanings.validation.partners")}
                required={false}
                showSearch={true}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    partner_id: e?.id,
                  });
                }}
              />
            </Col>

            {/* Select filter with building units */}
            <Col span={7}>
              <BuildingSelect
                name={"building_id"}
                label={false}
                initialValue={filters.building_id}
                onChange={(e) => setFilters({ ...filters, building_id: e ? e.id : '' })}
              />
            </Col>

            {/* Select filter with managers */}
            <Col span={8}>
              <ManagerSelect
                name={"manager_id"}
                label={false}
                validationMessage={t("cleanings.validation.partners")}
                required={false}
                showSearch={true}
                clearable={true}
                sendOnchange={(id) => {
                  setFilters({
                    ...filters,
                    manager_id: id,
                  });
                }}
              />
            </Col>

            {/* Select range of dates */}
            <Col span={6}>
              <Form.Item
                label={t("common.selectedPeriod")}
                name={"period"}
                initialValue={[filters?.date_from, filters?.date_to]}
              >
                <DatePicker.RangePicker
                  allowClear={false}
                  allowEmpty={false}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      date_from: value[0] || moment().startOf("month"),
                      date_to: value[1] || moment().endOf("month"),
                    });
                  }}
                  style={{ width: "100%" }}
                ></DatePicker.RangePicker>
              </Form.Item>
            </Col>

            {/* Group of radio options for cleaning type */}
            <Form.Item name={"is_recurring"}>
              <Checkbox
                className="radio-style-checkbox"
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    is_recurring: e.target.checked ? 2 : 1,
                  });
                }}
                checked={filters?.is_recurring === 2}
              >
                {t("common.selectedRecurring")}
              </Checkbox>
              <Checkbox
                className="radio-style-checkbox"
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    is_recurring: e.target.checked ? 3 : 1,
                  });
                }}
                checked={filters?.is_recurring === 3}
              >
                {t("common.selectedOneTime")}
              </Checkbox>
            </Form.Item>

            {/* Checkbox for discounted cleanings */}
            <Form.Item name={"is_promo"}>
              <Checkbox
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    is_promo: e.target.checked,
                  });
                }}
                value={filters?.is_promo}
                initialValue={filters?.is_promo}
              >
                {t("cleanings.onlyPromo")}
              </Checkbox>
            </Form.Item>

            {/* Checkbox for claimed cleanings */}
            <Form.Item name={"is_claim"}>
              <Checkbox
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    is_claim: e.target.checked,
                  });
                }}
                value={filters?.is_claim}
                initialValue={filters?.is_claim}
              >
                {t("cleanings.onlyClaimed")}
              </Checkbox>
            </Form.Item>

            {/* Filters reset button */}
            <Form.Item>
              <Link
                onClick={() => {
                  filterForm.resetFields();
                  setFilters(defaultFilters);
                }}
              >
                {t("common.resetFilters")}
              </Link>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      {/* Hidden container with mass update actions, it opens on cleaning select */}
      <SlideDown className={"my-dropdown-slidedown"} closed={selectedRows.length < 1}>
        <Space style={{ paddingBottom: "16px" }}>
          {/* Mass update of substitutes */}
          <MassSubstituteModal
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            partnerId={filters?.partner_id}
            refresh={handleTableChange}
          />

          {/* Mass update of promos */}
          <MassPromoModal
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            partnerId={filters?.partner_id}
            refresh={handleTableChange}
          />

          {/* Mass update of claims */}
          <MassClaimModal
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            partnerId={filters?.partner_id}
            refresh={handleTableChange}
            cleaning={cleaning}
          />
        </Space>
      </SlideDown>

      {/* Table with cleanings */}
      <Table
        className={"table-text-small"}
        size={"small"}
        rowKey={"id"}
        loading={isLoading}
        pagination={{
          ...pagination,
          // Handle current page and page size
          onChange: (page, pageSize) => {
            setFilters({ ...filters, page_size: pageSize, page: page });
          },
          showTotal: (total, range) => (
            <p>
              {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
              {" " + t("common.paginationPartTwo") + " "}
              <strong>{total}</strong> {t("common.paginationPartThree")}
            </p>
          ),
          showSizeChanger: true,
        }}
        rowSelection={{
          selectedRowKeys: selectedRows,
          selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
          // Handle selection of cleanings
          onChange: (selectedRowKeys, element) => {
            setSelectedRows(selectedRowKeys);
            if (element) setCleaning(element[0]);
          },
        }}
        columns={tableColumns}
        dataSource={[...cleanings]}
      />

      {/* Update form for a single cleaning */}
      <CleaningEditModal
        refreshParent={() => {
          handleTableChange();
        }}
        cleaning={selectedCleaning}
        isOpen={isEditModalOpen}
        close={() => {
          setEditModalOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export default CleaningsList;
