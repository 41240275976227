import {CheckOutlined, DownOutlined, EditOutlined, InfoCircleOutlined, RightOutlined} from "@ant-design/icons";
import {Button, notification, Space, Table, Tooltip, Typography} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import BuildingUnitLink from "../Buildings/BuildingUnitLink";
import CleaningEditModal from "../Cleanings/CleaningEditModal";
import PartnerLink from "../Partners/PartnerLink";

/**
 * Claims Pane Simple - list of claims of supplied user
 * @component
 * @alias ClaimsPaneSimple
 * @property {object} user
 * @returns
 */
const ClaimsPaneSimple = ({ user }) => {
  const { t } = useTranslation();

  const defaultPageSize = 10;

  // LOADING FLAGS
  const [claimsLoading, setClaimsLoading] = useState(false);

  // EDIT
  const [selectedCleaning, setSelectedCleaning] = useState();
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  // DATASOURCES
  const [claims, setClaims] = useState([]);

  // PARAMS
  const [params, setParams] = useState({
    date_from: null,
    date_to: null,
    users: [user.id],
    in_solution_only: 1,
    page: 1,
    page_size: 10,
  });

  // PAGINATIONS
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: t("cleanings.dateExpected"),
      key: "date_expected",
      dataIndex: "date_expected",
      render: (date) => moment(date).format("LL"),
    },
    {
      title: t("cleanings.claimTag"),
      key: "claim_tag",
      dataIndex: "claim_tag",
      render: (claimTag) => claimTag,
    },
    {
      title: t("cleanings.partner"),
      key: "partners",
      dataIndex: "partner",
      render: (partner) => <PartnerLink partner={partner} />,
    },
    {
      title: t("schedules.buildingUnit"),
      key: "building_unit_id",
      dataIndex: "building_unit",
      render: (buildingUnit) => <BuildingUnitLink buildingUnit={buildingUnit} />,
    },
    {
      title: t("cleanings.satisfactionCheckDate"),
      key: "date_satisfaction_check",
      render: (cleaning) =>
        cleaning.date_satisfaction_check ? moment(cleaning.date_satisfaction_check).format("L") : null,
    },
    {
      title: t("common.actions"),
      key: "actions",
      render: (cleaning) => (
        <Space>
          <Button
            size={"small"}
            icon={<EditOutlined />}
            onClick={() => {
              setSelectedCleaning(cleaning);
              setEditModalOpen(true);
            }}
          ></Button>
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    let newParams = { ...params };
    newParams.users = filters.users ?? params.users;
    newParams.partners = filters.partners;
    newParams.in_solution_only = filters.claim_status?.includes("in_solution_only") ?? params.in_solution_only;
    newParams.solved_only = filters.claim_status?.includes("solved_only") ?? params.solved_only;
    newParams.page = pagination.current;
    newParams.page_size = pagination.pageSize;
    setParams(newParams);
  };

  useEffect(() => {
    setClaimsLoading(true);

    coreApi
      .get("/claims", { params: params })
      .then((response) => {
        setClaims(response.data.data);
        setCurrentPage(response.data.current_page);
        setPageSize(response.data.per_page);
        setTotal(response.data.total);
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setClaimsLoading(false);
      });
  }, [params]);

  return (
    <React.Fragment>
      {claims.length > 0 ? (
        <React.Fragment>
          <Table
            size={"small"}
            rowKey={"id"}
            columns={columns}
            loading={claimsLoading}
            dataSource={claims}
            onChange={handleTableChange}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: total,
              showSizeChanger: true,
            }}
            expandable={{
              expandedRowRender: (claim) => {
                return (
                    <p style={{ margin: 0 }}>
                      <React.Fragment>
                        <strong>{t("cleanings.claimDescription")}</strong>
                        <div style={{ marginBottom: "6px", whiteSpace: "break-spaces" }}>
                          <em>{claim.claim_description}</em>
                        </div>
                      </React.Fragment>
                      <React.Fragment>
                        <strong>{t("cleanings.claimSolution")}</strong>
                        <div style={{ marginBottom: "6px", whiteSpace: "break-spaces" }}>
                          <em>{claim.claim_solution}</em>
                        </div>
                      </React.Fragment>
                    </p>
                );
              },
              expandIcon: ({ expanded, onExpand, record, expandable }) =>
                  expandable && (
                      <Tooltip placement="top" title={t("cleanings.showMore")}>
                        <InfoCircleOutlined
                            onClick={(e) => onExpand(record, e)}
                            style={{ transition: "linear 1s 0.2s" }}
                        />
                      </Tooltip>
                  ),
              rowExpandable: (claim) => claim?.claim_solution?.length > 0 || claim?.claim_description?.length > 0,
            }}
          ></Table>
          <CleaningEditModal
            cleaning={selectedCleaning}
            isOpen={isEditModalOpen}
            close={() => {
              setEditModalOpen(false);
            }}
            refreshParent={() => {
              handleTableChange({
                current: currentPage,
                pageSize: defaultPageSize,
              }, params);
            }}
          />
        </React.Fragment>
      ) : (
        <Typography.Text type={"secondary"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              minHeight: "150px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <CheckOutlined style={{ fontSize: 20, display: "grid" }} />
            <p>{t("dashboard.noAssignedClaims")}</p>
          </div>
        </Typography.Text>
      )}
    </React.Fragment>
  );
};

export default ClaimsPaneSimple;
