import React, { useEffect, useState } from "react";
import { Card, Col, Row, Skeleton, Tabs, Typography, Divider } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { coreApi } from "../api/calls";
import ClaimsPane from "../components/Claims/ClaimsPane";
import CleaningListComplex from "../components/Cleanings/CleaningListComplex";
import PartnerBalancePane from "../components/Partners/PartnerBalancePane";
import PartnerBalancePaneOld from "../components/Partners/PartnerBalancePaneOld";
import PartnerBuildingsListPane from "../components/Partners/PartnerBuildingsListPane";
import PartnerCalendarPane from "../components/Partners/PartnerCalendarPane";
import PartnerDocumentsPane from "../components/Partners/PartnerDocumentsPane";
import PartnerGeneralPane from "../components/Partners/PartnerGeneralPane";
import PartnerHeaderBar from "../components/Partners/PartnerHeaderBar";
import PartnerInvoicingPane from "../components/Partners/PartnerInvoicingPane";
import CustomTabs from "../components/custom/CustomTabs";
import PartnerTransactionPane from "../components/Partners/PartnerTransactionPane";

export const PartnerContext = React.createContext();

const PartnerProfile = (props) => {
  const { t } = useTranslation();

  const [partner, setPartner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [partnerFormReadOnly, setPartnerFormReadyOnly] = useState(true);
  const [activeTabKey, setActiveTabKey] = useState("#overview");

  const refreshPartnerState = (newState) => {
    setPartner({ ...newState });
  };

  const refreshPartnerFormReadOnly = (value) => {
    setPartnerFormReadyOnly(value);
  };

  useEffect(() => {
    setLoading(true);
    coreApi
      .get("/partners/" + props.match.params.id)
      .then((response) => {
        setPartner(response.data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [props.match.params.id]);

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Skeleton />
      ) : (
        <React.Fragment>
          <PartnerContext.Provider
            value={{ partner, refreshPartnerState, partnerFormReadOnly, refreshPartnerFormReadOnly }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card>
                  <PartnerHeaderBar
                    companyName={partner?.company_name}
                    firstName={partner?.first_name}
                    lastName={partner?.last_name}
                    createdAt={partner?.created_at}
                    updatedAt={partner?.updated_at}
                    idPartner={partner?.id}
                    contractors={partner?.contractors}
                    workers={partner?.workers}
                  ></PartnerHeaderBar>

                  <CustomTabs props={props} onChange={handleTabChange}>
                    <Tabs.TabPane tab={t("common.profile")} key="#overview">
                      <PartnerGeneralPane />
                    </Tabs.TabPane>
                    {/*<Tabs.TabPane tab={t("partners.cleaningsOverview")} key="#cleaningsOverview">
                      <PartnerCalendarPane partner={partner}></PartnerCalendarPane>
                    </Tabs.TabPane>*/}
                    <Tabs.TabPane tab={t("partners.cleaningsList")} key="#cleaningsList">
                      <CleaningListComplex partner={partner} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("partners.buildingsList")} key="#buildingsList">
                      <PartnerBuildingsListPane partner={partner} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("cleanings.claims")} key="#claims">
                      <ClaimsPane partner={partner} showStatistics={true} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("partners.balances_old")} key="#balances_old">
                      {activeTabKey === "#balances_old" && (
                        <PartnerBalancePaneOld partner={partner} activeTabKey={activeTabKey}/>
                      )}
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={t("partners.balances")} key="#balances">
                      {activeTabKey === "#balances" && (
                        <PartnerBalancePane partner={partner} activeTabKey={activeTabKey}/>
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("finance.transactions.transaction")} key="#transactions">
                        <PartnerTransactionPane partner={partner}/>
                    </Tabs.TabPane>
                    {/* <Tabs.TabPane tab={t("partners.payouts")} key="#payouts">
                      <PartnerPayoutPane partner={partner} />
                    </Tabs.TabPane> */}
                    <Tabs.TabPane tab={t("partners.invoicing")} key="#invoicing">
                      <PartnerInvoicingPane props={props} partner={partner} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("common.documents")} key="#documents">
                      <PartnerDocumentsPane partner={partner} loading={loading} />
                    </Tabs.TabPane>
                    {/* <Tabs.TabPane
                    tab={t("partners.activityMap")}
                    key="activityMap"
                  >
                    <PartnerActivityMapPane partner={partner} />
                  </Tabs.TabPane> */}
                  </CustomTabs>
                  <Divider />
                  <Typography.Text type="secondary">
                    {t("common.recordCreated") + ": "}
                    {partner?.created_at ? moment(partner?.created_at).format("LL") : "-"}
                  </Typography.Text>
                  <br></br>
                  <Typography.Text type="secondary">
                    {t("common.recordLastUpdate") + ": "}
                    {partner?.updated_at ? moment(partner?.updated_at).format("LL") : "-"}
                  </Typography.Text>
                </Card>
              </Col>
            </Row>
          </PartnerContext.Provider>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PartnerProfile;
