import { Form, notification, Select, Spin } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { coreApi } from "../../api/calls";

const BuildingUnitSelect = ({
  label,
  name,
  initialValue = null,
  disabledOption,
  readOnly,
  required,
  validationMessage,
  size,
  onChange = null,
  buildingId = null,
  partnerId = null,
  cityBranchId = null,
  cleaningDay = null,
  mode = "single",
  selectAllOnLoad = false,
  form = null,
  defaultBuildingUnitList = []
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [buildingUnits, setBuildingUnits] = useState(defaultBuildingUnitList);
  const [selectedUnits, setSelectedUnits] = useState(initialValue || []);
  const [searchQuery, setSearchQuery] = useState("");

  const isMounted = useRef(true);
  const prevBuildingId = useRef(buildingId);
  const prevPartnerId = useRef(partnerId);

  useEffect(() => {
    if (defaultBuildingUnitList.length !== 0) {
      if (selectAllOnLoad && form) {
        const allIds = buildingUnits.map(unit => unit.id);
        setSelectedUnits(allIds);
        form.setFields([{ name, value: allIds }]);
      }
      return;
    }

    // Prevent unnecessary API calls when data is already loaded or search query is too short without filters.
    if ((buildingUnits.length > 0 && searchQuery.length >= 2) || (isMainFiltersEmpty() && searchQuery.length < 2)) {
      return;
    }

    if (
      partnerId !== null
      && buildingId !== null
      && partnerId !== prevPartnerId.current
    ) {
      prevBuildingId.current = buildingId;
      prevPartnerId.current = partnerId;
      return;
    }

    prevBuildingId.current = buildingId;
    prevPartnerId.current = partnerId;

    isMounted.current = true;
    const params = {
      building_id: buildingId,
      partner_id: partnerId,
      city_branch_id: cityBranchId,
      cleaning_day: cleaningDay,
      street: searchQuery.trim() || undefined
    };

    setLoading(true);
    coreApi
      .get("/buildings/units/all", { params })
      .then((response) => {
        if (isMounted.current) {
          setBuildingUnits(response.data);
          if (selectAllOnLoad && form && (partnerId || buildingId)) {
            const allIds = response.data.map(unit => unit.id);
            setSelectedUnits(allIds);
            form.setFields([{ name, value: allIds }]);
          }
        }
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });

    return () => {
      isMounted.current = false;
    };
  }, [buildingId, partnerId, cityBranchId, cleaningDay, searchQuery]);

  const handleSearch = debounce((value) => {
    const trimmedValue = value?.trim() || "";
    setSearchQuery(trimmedValue);

    if (trimmedValue.length < 2) {
      setBuildingUnits([]);
    }
  }, 500);

  const isMainFiltersEmpty = () => (!buildingId && !partnerId && !cityBranchId && !cleaningDay);

  const handleChange = (value) => {
    setSelectedUnits(value);
    onChange && onChange(value);
  };

  return (
    <Form.Item
      hasFeedback
      name={name}
      label={label}
      valuePropName={"value"}
      rules={[
        {
          required: required,
          message: validationMessage,
        },
      ]}
    >
      <Select
        size={size}
        allowClear
        disabled={readOnly}
        loading={loading}
        showSearch={true}
        mode={mode}
        placeholder={t("buildings.selectUnit")}
        filterOption={(input, option) => {
          if (buildingUnits.length > 0) {
            return option.children.toLowerCase().includes(input.toLowerCase());
          }
          return !isMainFiltersEmpty() || searchQuery.length >= 2;
        }}
        onSearch={handleSearch}
        onChange={handleChange}
        value={selectedUnits}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {buildingUnits.map((unit) => {
          return (
            <Select.Option
              key={unit?.id}
              value={unit?.id}
              disabled={disabledOption && unit?.id === disabledOption}
            >
              {unit?.street + " " + unit?.house_number}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default BuildingUnitSelect;
