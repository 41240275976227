import { Button, Card, Col, Divider, notification, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../../../api/calls";

const CountryReportTemplate = ({ year, APIurl, cityDataPropName, countryDataPropName, currencyValues = false }) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [showValuesInCZK, setShowValuesInCZK] = useState(true);

  const [cityData, setCityData] = useState([]);
  const [cityFiltersArray, setCityFiltersArray] = useState([]);
  const [countryData, setCountryData] = useState([]);

  const [convertedCityData, setConvertedCityData] = useState([]);
  const [convertedCountryData, setConvertedCountryData] = useState([]);

  // For this CountryReportTemplate custom modifications of different tables are needed to fulfill business requirements
  const noAnnaToPartnerUrl = APIurl !== "/reports/country/anna-partner-invoices";
  const noUnpaidInvoicesUrl = APIurl !== "/reports/country/unpaid-invoices";
  const noActiveBuildingsUrl = APIurl !== "/reports/country/active-buildings";
  const noActivePartnersUrl = APIurl !== "/reports/country/active-partners";
  const noCleaningsUrl = APIurl !== "/reports/country/cleanings";
  const noCleaningsActiveBuildingsPartnersUrl = noActiveBuildingsUrl && noActivePartnersUrl && noCleaningsUrl;
  const noActiveBuildingsPartnersUrl = noActiveBuildingsUrl && noActivePartnersUrl;

  const filterColumnsByRecurrence = (tableColumns) => {
    return tableColumns.filter((tableColumn) => {
      if (noAnnaToPartnerUrl && noUnpaidInvoicesUrl && noCleaningsActiveBuildingsPartnersUrl) return tableColumn;
      return tableColumn.key !== "recurrence";
    });
  };

  useEffect(() => {
    if (year === undefined) return;

    setLoading(true);
    coreApi
      .get(APIurl, {
        params: { year: year?.format("YYYY-MM-DD") },
      })
      .then((res) => {
        if (res.data["aggregate_total"]) {
          res.data[
            noCleaningsActiveBuildingsPartnersUrl ? `converted_${countryDataPropName}` : countryDataPropName
          ].push(res.data["aggregate_total"]);
        }
        if (res.data["cleanings_count_city"]) {
          setCityData(res.data["cleanings_count_city"])
        }
        setCityFiltersArray(
          [...new Map(
            res.data[cityDataPropName].map((city) => [city.name, { text: city.name, value: city.name }])
          ).values()]
            .sort((a, b) => a.text.localeCompare(b.text))
        );
        setCountryData([...res.data[countryDataPropName]]);

        if (currencyValues) {
          setConvertedCityData([...res.data["converted_" + cityDataPropName]]);
          setConvertedCountryData([...res.data["converted_" + countryDataPropName]]);
        }
      })
      .catch((err) => notification.error({ message: err?.response?.data?.message }))
      .finally(() => {
        setLoading(false);
      });
  }, [
    year,
    APIurl,
    cityDataPropName,
    countryDataPropName,
    currencyValues,
    noCleaningsActiveBuildingsPartnersUrl,
    noActiveBuildingsUrl,
  ]);

  const cityTableColumns = [
    {
      title: t("common.cityBranch"),
      key: "city_branch_id",
      width: 150,
      fixed: "left",
      render: (row) => {
        return <Link to={"/countries/" + row.country_branch_id + "/cities/" + row.city_branch_id}>{row.name}</Link>;
      },
      filters: cityFiltersArray,
      onFilter: (value, record) => record?.name === value,
    },
    {
      title: t("expansion.countries.cleaningType"),
      key: "recurrence",
      width: 150,
      fixed: "left",
      render: (row) => {
        switch (row.recurring) {
          case 1:
            return t("expansion.countries.periodic");
          case 0:
            return t("expansion.countries.oneTime");
          default:
            return t("expansion.countries.unknown");
        }
      },
      filters: [
        {
          text: t("expansion.countries.periodic"),
          value: 1,
        },
        {
          text: t("expansion.countries.oneTime"),
          value: 0,
        },
      ],
      onFilter: (value, record) => record?.recurring === value,
    },
    {
      title: t("expansion.cityManager"),
      key: "employee_id",
      width: 150,
      fixed: "left",
      render: (row) => {
        if (
          (!row?.employee?.first_name || !row?.employee?.last_name) &&
          (!row?.employee_first_name || !row?.employee_last_name)
        )
          return t("common.empty");
        const employeeName = row.employee
          ? row?.employee?.first_name + " " + row?.employee?.last_name
          : row?.employee_first_name + " " + row?.employee_last_name;
        return <Link to={"/employees/" + (row?.employee ? row?.employee?.id : row?.employee_id)}>{employeeName}</Link>;
      },
    },
  ];

  const countryTableColumns = [
    {
      title: t("common.country"),
      key: "country_branch_id",
      width: 150,
      fixed: "left",
      render: (row) => {
        if (!row.country_branch_id) return row.name;
        return <Link to={"/countries/" + row.country_branch_id}>{row.name}</Link>;
      },
      filters: [
        {
          text: "Czech Republic",
          value: "Czech Republic",
        },
        {
          text: "Slovakia",
          value: "Slovakia",
        },
        {
          text: "Ireland",
          value: "Ireland",
        },
        {
          text: "Aggregate",
          value: "Aggregate",
        },
      ],
      onFilter: (value, record) => record?.name?.includes(value),
    },
    {
      title: t("expansion.countries.cleaningType"),
      key: "recurrence",
      width: 150,
      fixed: "left",
      render: (row) => {
        switch (row.recurring) {
          case 1:
            return t("expansion.countries.periodic");
          case 0:
            return t("expansion.countries.oneTime");
          default:
            return t("expansion.countries.unknown");
        }
      },
      filters: [
        {
          text: t("expansion.countries.periodic"),
          value: 1,
        },
        {
          text: t("expansion.countries.oneTime"),
          value: 0,
        },
      ],
      onFilter: (value, record) => record?.recurring === value,
    },
  ];

  const filteredCityTableColumns = filterColumnsByRecurrence(cityTableColumns);
  const filteredCountryTableColumns = filterColumnsByRecurrence(countryTableColumns);

  const monthColumnsValues = [
    {
      title: moment().month(0).format("MMMM"),
      key: "1",
      width: 150,
      render: (row) => {
        return row["1"];
      },
    },
    {
      title: moment().month(1).format("MMMM"),
      key: "2",
      width: 150,
      render: (row) => {
        return row["2"];
      },
    },
    {
      title: moment().month(2).format("MMMM"),
      key: "3",
      width: 150,
      render: (row) => {
        return row["3"];
      },
    },
    {
      title: moment().month(3).format("MMMM"),
      key: "4",
      width: 150,
      render: (row) => {
        return row["4"];
      },
    },
    {
      title: moment().month(4).format("MMMM"),
      key: "5",
      width: 150,
      render: (row) => {
        return row["5"];
      },
    },
    {
      title: moment().month(5).format("MMMM"),
      key: "6",
      width: 150,
      render: (row) => {
        return row["6"];
      },
    },
    {
      title: moment().month(6).format("MMMM"),
      key: "7",
      width: 150,
      render: (row) => {
        return row["7"];
      },
    },
    {
      title: moment().month(7).format("MMMM"),
      key: "8",
      width: 150,
      render: (row) => {
        return row["8"];
      },
    },
    {
      title: moment().month(8).format("MMMM"),
      key: "9",
      width: 150,
      render: (row) => {
        return row["9"];
      },
    },
    {
      title: moment().month(9).format("MMMM"),
      key: "10",
      width: 150,
      render: (row) => {
        return row["10"];
      },
    },
    {
      title: moment().month(10).format("MMMM"),
      key: "11",
      width: 150,
      render: (row) => {
        return row["11"];
      },
    },
    {
      title: moment().month(11).format("MMMM"),
      key: "12",
      width: 150,
      render: (row) => {
        return row["12"];
      },
    },
  ];

  const monthColumnsCurrency = [
    {
      title: moment().month(0).format("MMMM"),
      key: "1",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["1"]);
      },
    },
    {
      title: moment().month(1).format("MMMM"),
      key: "2",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["2"]);
      },
    },
    {
      title: moment().month(2).format("MMMM"),
      key: "3",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["3"]);
      },
    },
    {
      title: moment().month(3).format("MMMM"),
      key: "4",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["4"]);
      },
    },
    {
      title: moment().month(4).format("MMMM"),
      key: "5",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["5"]);
      },
    },
    {
      title: moment().month(5).format("MMMM"),
      key: "6",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["6"]);
      },
    },
    {
      title: moment().month(6).format("MMMM"),
      key: "7",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["7"]);
      },
    },
    {
      title: moment().month(7).format("MMMM"),
      key: "8",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["8"]);
      },
    },
    {
      title: moment().month(8).format("MMMM"),
      key: "9",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["9"]);
      },
    },
    {
      title: moment().month(9).format("MMMM"),
      key: "10",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["10"]);
      },
    },
    {
      title: moment().month(10).format("MMMM"),
      key: "11",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["11"]);
      },
    },
    {
      title: moment().month(11).format("MMMM"),
      key: "12",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["12"]);
      },
    },
  ];

  const cityTotalColumnValues = {
    title: t("common.total"),
    key: "city_total",
    fixed: "right",
    width: 200,
    render: (row) => {
      return row.city_total;
    },
  };

  const cityTotalColumnCurrency = {
    title: t("common.total"),
    key: "city_total",
    fixed: "right",
    width: 200,
    render: (row) => {
      return new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: row?.currency_iso_4217 || "CZK",
      }).format(row.city_total);
    },
  };

  const countryTotalColumnValues = {
    title: t("common.total"),
    key: "country_total",
    fixed: "right",
    width: 200,
    render: (row) => {
      return row.country_total;
    },
  };

  const countryTotalColumnCurrency = {
    title: t("common.total"),
    key: "country_total",
    fixed: "right",
    width: 200,
    render: (row) => {
      return new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: row.currency_iso_4217 || "CZK",
      }).format(row.country_total);
    },
  };

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        {currencyValues && (
          <React.Fragment key={"currency_changer"}>
            <Col span={24}>
              <Button
                onClick={() => {
                  setShowValuesInCZK(!showValuesInCZK);
                }}
              >
                {showValuesInCZK ? t("reports.country.showOrigVals") : t("reports.country.showCZKVals")}
              </Button>
            </Col>
            <Divider></Divider>
          </React.Fragment>
        )}
        <Col span={24}>
          <Card loading={isLoading} size="small" bodyStyle={{ padding: "10px" }}>
            <Table
              loading={isLoading}
              size={"small"}
              dataSource={showValuesInCZK && noCleaningsActiveBuildingsPartnersUrl ? convertedCountryData : countryData}
              columns={
                currencyValues
                  ? [...filteredCountryTableColumns, ...monthColumnsCurrency, countryTotalColumnCurrency]
                  : [
                      ...filteredCountryTableColumns,
                      ...monthColumnsValues,
                      noActiveBuildingsPartnersUrl ? countryTotalColumnValues : [],
                    ]
              }
              pagination={false}
              scroll={{ x: "80%" }}
            ></Table>
          </Card>
        </Col>
        <Divider></Divider>
        <Col span={24}>
          <Card loading={isLoading} size="small" bodyStyle={{ padding: "10px" }}>
            <Table
              loading={isLoading}
              size={"small"}
              dataSource={showValuesInCZK && noCleaningsActiveBuildingsPartnersUrl ? convertedCityData : cityData}
              columns={
                currencyValues
                  ? [
                      ...filteredCityTableColumns,
                      ...monthColumnsCurrency,
                      noActiveBuildingsPartnersUrl ? cityTotalColumnCurrency : [],
                    ]
                  : [
                      ...filteredCityTableColumns,
                      ...monthColumnsValues,
                      noActiveBuildingsPartnersUrl ? cityTotalColumnValues : [],
                    ]
              }
              pagination={false}
              sticky={true}
              scroll={{ y: `calc(100vh - 240px)`, x: "80%" }}
            ></Table>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CountryReportTemplate;
