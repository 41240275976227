import React, {useEffect, useState} from "react";
import {Button, Card, Col, DatePicker, Divider, Form, Input, Modal, notification, Row, Select} from "antd";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import { useTranslation } from "react-i18next";
import PartnerSelect from "../Partners/PartnerSelect";
import DaySelect from "../DaySelect";
import {coreApi} from "../../api/calls";

const ClientInformSelect = (selectedBuildingUnits) => {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [manager, setManager] = useState(null);
  const [partner, setPartner] = useState(null)
  const [managerName, setManagerName] = useState(null);
  const [partnerName, setPartnerName] = useState(null);
  const [week, setWeek] = useState(null);
  const [days, setDays] = useState([]);
  const [date, setDate] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isButtonReady, setIsButtonReady] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [reschedulingDates, setReschedulingDates] = useState([null]);

  const options = ["replacement", "switch", "change", "rescheduling"];
  const popupStyles = {
    marginTop: "-158px",
    marginLeft: "330px",
    padding: "8px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    zIndex: 1000,
    position: "absolute",
    background: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  };

  useEffect(() => {
    if (
      (week && manager && selectedOption === 'replacement') ||
      (partner && days.length > 0 && selectedOption === 'switch') ||
      (days.length > 0 && selectedOption === 'change') ||
      date && selectedOption === 'rescheduling'
    ) {
      setIsButtonReady(true);
    } else {
      setIsButtonReady(false);
    }
  }, [week, manager, partner, days, date, selectedOption]);

  const clear = (all = true) => {
    setIsModalVisible(false);
    if (all) {
      setOpenDropdown(false);
      setSelectedOption(null);
    }
    setManager(null);
    setPartner(null);
    setManagerName(null);
    setPartnerName(null);
    setWeek(null);
    setDays([]);
    setDate(null);
    setReschedulingDates([null]);
  }

  const sendData = () => {
    let selectedUnit = selectedBuildingUnits.values[0]
    setSubmitting(true);
    coreApi
      .post("partners/inform-client", {
        'building_units': selectedBuildingUnits.values.map(item => item.id),
        'manager': manager,
        'partner': partner,
        'week': formatDate(week),
        'days': formatDays(days),
        'date': date ?? null,
        'type': selectedOption,
        'text': document.getElementById('preview').innerText,
        'partner_id': selectedUnit?.service_rrules?.[selectedUnit?.service_rrules?.length - 1]?.partner.id
      })
      .then((response) => {
        clear();
        notification.success({ message: t('schedules.informEmailSentSuccess') });
      })
      .catch((error) => {
        console.error('Failed to send notification:', error);
      })
      .finally(() => setSubmitting(false));
  };

  const formatDate = (week) => {
    if (!week) return "";

    const [startDate, endDate] = week;
    if (startDate.format("DD.MM.YYYY") !== endDate.format("DD.MM.YYYY")) {
      return `${startDate.format("DD.MM.")} - ${endDate.format("DD.MM.YYYY")}`;
    } else {
      return startDate.format("DD.MM.YYYY");
    }
  };

  const formatDays = (days) => {
    if (!days.length) return "";

    return days
      .map((item) => t("rrules.byweekday." + item))
      .join(", ")
      .toLowerCase();
  };

  const handleDateChange = (index, date) => {
    const newDates = [...reschedulingDates];
    newDates[index] = date;

    if (newDates[index] && index === reschedulingDates.length - 1) {
      newDates.push(null);
    }

    setReschedulingDates(newDates);
    setDate(
      Array.from(
        new Set(
          newDates
            .filter((item) => item)
            .map((item) => item.format("DD.MM.YYYY"))
        )
      ).join(", ")
    );
  };

  const renderContent = () => {
    switch (selectedOption) {
      case "replacement":
        return (
          <>
            <RangePicker
              placeholder={[t("common.dateFrom"), t("common.dateTo")]}
              style={{ width: "100%", marginBottom: "8px" }}
              allowClear={false}
              onChange={(week) => setWeek(week)}
            />
            <PartnerSelect
              clearable
              showSearch
              placeholder={t("managers.selectManager")}
              allowClear
              style={{ width: "100%" }}
              activeOnly
              onChange={(manager) => {
                setManagerName(manager ? manager.first_name + ' ' + manager.last_name : '')
                setManager(manager ? manager.id : null)
              }}
            />
          </>
        );
      case "switch":
        return (
          <>
            <DaySelect setDays={(days) => setDays(days)} style={{ display: "block" }} />
            <PartnerSelect
              onChange={(partner) => {
                setPartnerName(partner ? partner.first_name + ' ' + partner.last_name : '')
                setPartner(partner ? partner.id : null)
              }}
              label=""
              placeholder={t("partners.selectPartner")}
              activeUsers={true}
            />
          </>
        );
      case "change":
        return <DaySelect setDays={(days) => setDays(days)} style={{ display: "block" }} />;
      case "rescheduling":
        return (
          <>
            {reschedulingDates.map((date, index) => (
              <DatePicker
                key={index}
                value={date}
                onChange={(date) => handleDateChange(index, date)}
                style={{ marginBottom: "10px" }}
              />
            ))}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <Button size="small" onClick={() => setOpenDropdown(openDropdown ? clear : !openDropdown)}>
        {t("schedules.informClient")}
      </Button>
      <SlideDown className="my-dropdown-slidedown" style={{'display': (openDropdown ? 'block' : 'none')}}>
        {openDropdown && (
          <div
            style={{
              ...popupStyles,
              width: "200px",
              marginLeft: "130px",
              display: openDropdown ? 'block' : 'none'
            }}
          >
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectedOption(option);
                  clear(false);
                }}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                  transition: "background-color 0.2s",
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = "#f0f0f0")}
                onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
              >
                {t("schedules.informClientOptions." + option)}
              </div>
            ))}
          </div>
        )}
      </SlideDown>
      {selectedOption && <div style={{ ...popupStyles, minWidth: "200px", 'display': (openDropdown ? 'block' : 'none') }}>
        {renderContent()}
        {isButtonReady && (
          <Button style={{ float: "right" }} onClick={() => setIsModalVisible(true)}>
            {t("common.continue")}
          </Button>
        )}
      </div>}
      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width={1000}
        title={t('partners.previewInformEmail')}
        footer={[
          <Button
            key="finish"
            onClick={sendData}
            type="primary"
            loading={submitting}
          >
            Potvrdit
          </Button>,
        ]}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Card>
              <Col span={24} style={{ marginBottom: "12px" }} id="preview">
                <p>Dobrý den,</p>
                {(() => {
                  switch (selectedOption) {
                    case "replacement":
                      return (
                        <>
                          <p>rádi bychom Vás informovali o záskoku v rámci Vašich prostor.</p>
                          <p><b>
                            Úklid zajistí v termínu {formatDate(week)} náhradní partner {managerName ?? ''}.
                          </b></p>
                          <p>
                            Nemusíte se o nic starat, vše najdete na rozpisu úklidů.
                          </p>
                        </>
                      );
                    case "switch":
                      return (
                        <>
                          <p>rádi bychom Vás informovali, že v rámci Vašich prostor proběhne výměna partnera</p>
                          <p><b>
                            Nově se bude o Váš prostor starat {partnerName ?? ''}, společně s tím se
                            Vám mění úklidový den na {formatDays(days)}.
                          </b></p>
                          <p>Nemusíte se o nic starat, vše najdete na rozpisu úklidů.</p>
                          <p>Jsme přesvědčeni, že tento krok povede k Vaší spokojenosti.</p>
                        </>
                      );
                    case "change":
                      return (
                        <>
                          <p>rádi bychom Vás informovali, že proběhne změna úklidového dne Vašich prostor.</p>
                          <p><b>Novým pravidelným úklidovým dnem bude {formatDays(days)}.</b></p>
                          <p>Nemusíte se o nic starat, vše najdete na rozpisu úklidů.</p>
                        </>
                      );
                    case "rescheduling":
                      return (
                        <>
                          <p>rádi bychom Vás informovali o přesunu úklidu Vašich prostor.</p>
                          <p><b>
                            Místo Vašeho běžného úklidového dne proběhne z organizačních důvodů
                            úklid {date ?? ''}.
                          </b></p>
                          <p>Nemusíte se o nic starat, vše najdete na rozpisu úklidů.</p>
                        </>
                      );
                    default:
                      return null;
                  }
                })()}
                <p>Děkujeme za pochopení a přejeme Vám krásný den!</p>
              </Col>
            </Card>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ClientInformSelect;
