import { DatePicker, Divider, Form, Input, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import countriesData from "../../data/country-ISO3166-1.alpha2.json";
import moment from "moment/moment";

const PartnerAdditionalInformationSection = ({ partner, readOnly }) => {
  const { t } = useTranslation();
  const countriesArray = Object.keys(countriesData).map(code => ({
    code,
    name: countriesData[code]
  }));
  return (
    <React.Fragment>
      <Divider orientation="left">{t("common.additionalInformation")}</Divider>
      <Form.Item
        name="partner_type"
        label={t("common.typeBusinessEntity")}
        rules={[{ required: true }]}
        initialValue={partner?.partner_type || false}
        hasFeedback
      >
        <Select disabled={readOnly} bordered={!readOnly}>
          <Select.Option value="individual">{t("common.individual")}</Select.Option>
          <Select.Option value="company">{t("common.company")}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="country_of_birth"
        label={t("common.countryOfBirth")}
        initialValue={partner?.country_of_birth || false}
        rules={[{ required: true }]}
        hasFeedback
        >
        <Select disabled={readOnly} bordered={!readOnly} showSearch={true}>
          {countriesArray.map(country => (
            <Select.Option key={country.code} value={country.code}>
              {country.code}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="birth_city"
        label={t("common.birthCity")}
        initialValue={partner?.birth_city}
        rules={[{ required: true }]}
        hasFeedback
        >
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>

      <Form.Item
        name="birth_date"
        label={t("common.birthDate")}
        initialValue={partner?.birth_date ? moment(partner.birth_date) : null}
        rules={[{ required: true }]}
        hasFeedback
        >
        <DatePicker disabled={readOnly} bordered={!readOnly} format={"L"}></DatePicker>
      </Form.Item>

      <Form.Item
        name="country_for_id"
        label={t("common.countryForId")}
        initialValue={partner?.country_for_id || false}
        rules={[{ required: true }]}
        hasFeedback
        >
        <Select disabled={readOnly} bordered={!readOnly} showSearch={true}>
          {countriesArray.map(country => (
            <Select.Option key={country.code} value={country.code}>
              {country.code}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="residency"
        label={t("common.residency")}
        initialValue={partner?.residency || false}
        rules={[{ required: true }]}
        hasFeedback
        >
        <Select disabled={readOnly} bordered={!readOnly} showSearch={true}>
          {countriesArray.map(country => (
            <Select.Option key={country.code} value={country.code}>
              {country.code}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="vat_number"
        label={t("common.vatNumber")}
        rules={[{ min: 5, max: 34, required: true }]}
        hasFeedback
      >
        {readOnly ? (
          <div className="ant-form-text">{partner?.vat_number || ""}</div>
        ) : (
          <Input bordered={!readOnly} />
        )}
      </Form.Item>

      <Form.Item
        name="vat_payers_number"
        label={t("common.vatPayersNumber")}
        initialValue={partner?.vat_payers_number}
        rules={[{
          min: 5,
          max: 34
        }]}
        hasFeedback
        >
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>

    </React.Fragment>
  );
};

export default PartnerAdditionalInformationSection;
