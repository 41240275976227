import {
  Button,
  Table,
  Typography,
  Modal,
  Space,
  notification,
  Tooltip,
  Tabs,
  Col,
  Row,
  Empty,
} from "antd";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getAreaScope } from "../../actions";
import { coreApi } from "../../api/calls";
import { useSelector } from "react-redux";
import { DeleteOutlined, ExclamationCircleOutlined, SendOutlined } from "@ant-design/icons";
import PartnerBalanceCitySelector from "../Partners/PartnerBalanceCitySelector";
import PaymentsForCheckingPane from "./PaymentsForCheckingPane";
import CityAndBankAccountsOverviewPane from "./CityAndBankAccountsOverviewPane";
import CityStatisticsCard from "./CityStatisticsCard";

const PaymentsForApprovalPane = () => {
  const { t } = useTranslation();

  const user = useRef({ ...JSON.parse(localStorage.getItem("user")) }).current;
  const role = user?.roles[user?.roles?.length - 1];
  // const isAdmin = role?.name === "admin";
  const isAdmin = false;

  const areaScope = useSelector(getAreaScope);

  const [selectedCity, setSelectedCity] = useState(areaScope?.areaScope || null);
  const [dataLoading, setDataLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({ total: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [sorter, setSorter] = useState();
  const [filters, setFilters] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [activeTab, setActiveTab] = useState(localStorage.getItem("paymentsForApprovalPaneActiveTab") || "1");

  const handleTabChange = (key) => {
    setActiveTab(key);
    localStorage.setItem("paymentsForApprovalPaneActiveTab", key);
  };

  const cellStyle = {
    direction: "rtl",
    fontWeight: "bold",
    paddingRight: "4px",
  };

  const tableColumns = [
    {
      title: t("finance.transactions.paymentEntryDate"),
      dataIndex: "sent_at",
      render: (text) => (text ? moment(text).format("L") : ""),
    },
    {
      title: t("finance.transactions.dueDate"),
      dataIndex: "due_date",
      render: (text) => (text ? moment(text).format("L") : ""),
    },
    {
      title: t("finance.transactions.cityBranch"),
      key: "city",
      render: (row) => (
        <Typography.Text>{row?.city_branch?.name || row?.city_branch_id}</Typography.Text>
      ),
    },
    {
      title: t("finance.transactions.senderAccountNumber"),
      key: "account_number_from",
      render: (row) => (
        <Typography.Text copyable key={row.id}>
          {row.account_number_from + "/" + row.bank_code_from}
        </Typography.Text>
      ),
    },
    {
      title: t("finance.transactions.recipientAccountNumber"),
      key: "account_number_to",
      render: (row) => (
        <Typography.Text copyable key={row.id}>
          {row.account_number_to + "/" + row.account_bank_code_to}
        </Typography.Text>
      ),
    },
    {
      title: t("finance.transactions.paymentType"),
      key: "payment_type",
      render: (rowData) => (
        <Typography.Text>
          {rowData?.payment_type ? t("finance.transactions." + rowData?.payment_type) : "-"}
        </Typography.Text>
      ),
    },
    {
      title: t("finance.transactions.companyName"),
      key: "company_name",
      width: 250,
      render: (rowData) => (
        <Tooltip title={rowData.company_name}>
          <span style={{ borderBottom: "1px dotted" }}>{rowData.company_name}</span>
        </Tooltip>
      ),
    },
    {
      title: t("finance.transactions.paymentAmount"),
      key: "amount",
      sorter: true,
      render: (rowData) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: rowData.currency || "CZK",
        }).format(rowData.amount);
      },
    },
    {
      title: "Actions",
      render: (text, item) =>
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() =>
            Modal.confirm({
              title: t("common.delete"),
              icon: <ExclamationCircleOutlined />,
              okButtonProps: { danger: true, loading: dataLoading },
              onOk: () => {
                handleDelete(item);
              },
            })
          }
        />,
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: moment(record.due_date) < moment(),
    }),
  };

  const handleSaveSelected = () => {
    if (selectedRowKeys.length > 0) {
      setDataLoading(true);

      coreApi
        .post("/approval-payment/send-selected-approval-payment", { paymentIds: selectedRowKeys })
        .then((res) => {
          notification.success({
            message: t("finance.transactions.selectedRowsSavedSuccessfully"),
          });
          setSelectedRowKeys([]);
          fetchData();
        })
        .catch((err) =>
          notification.error({
            message: err?.response?.data?.message,
            description: err?.response?.data?.error,
          }),
        )
        .finally(() => setDataLoading(false));
    }
  };

  const handleMassDelete = () => {
    setDataLoading(true);

    const deletePromises = selectedRowKeys.map((id) => {
      const item = transactions.find((x) => x.id === id);
      return coreApi.delete("/approval-payment/" + item.id);
    });

    Promise.all(deletePromises)
      .then(() => {
        notification.success({ message: t("common.deleteSuccess") });
        setSelectedRowKeys([]);
        fetchData();
      })
      .catch((err) => notification.error({ message: err?.response?.data?.message }))
      .finally(() => setDataLoading(false));
  };

  const handleDelete = (item) => {
    setDataLoading(true);

    coreApi
      .delete("/approval-payment/" + item.id)
      .then((res) => {
        notification.success({ message: res?.data?.message });
        setTransactions([...transactions.filter((x) => x !== item)]);
        setPaginationDetails((paginationDetails.total || 1) - 1);
      })
      .catch((err) => notification.error({ message: err?.response?.data?.message }))
      .finally(() => setDataLoading(false));
  };

  const fetchData = () => {
    if (!selectedCity) return;

    setDataLoading(true);
    coreApi
      .get("/approval-payment", {
        params: {
          page: currentPage,
          order_by_column: sorter?.columnKey || null,
          order_by_desc: sorter?.order === "descend" ? "DESC" : null,
          city_branch_id: Number(selectedCity) ? Number(selectedCity) : null,
          ...filters,
        },
      })
      .then((res) => {
        let { data, ...pagination } = res.data;
        setTransactions(data || []);
        setPaginationDetails(pagination);
      })
      .catch((err) => console.log(err))
      .finally(() => setDataLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, sorter, filters]);

  useEffect(() => {
    fetchData();
  }, [selectedCity]);

  return (
    <Tabs activeKey={activeTab} onChange={handleTabChange}>
      <Tabs.TabPane tab={t("finance.transactions.paymentsForChecking")} key="1">
        <PaymentsForCheckingPane />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t("finance.transactions.paymentsForApproval")} key="2">
        <React.Fragment>
          <Row gutter={[16, 0]} style={{ marginBottom: 6 }}>
            <Col span={8}>
              <PartnerBalanceCitySelector
                name={"city_branch_id"}
                label={t("expansion.cities")}
                required={true}
                showSearch={true}
                setValue={(value) => {
                  setSelectedCity(value);
                  setCurrentPage(1);
                }}
              />
            </Col>
          </Row>
          {!selectedCity ? (
            <Empty description={t("expansion.selectCity")} />
          ) : (
            <>
              <Col span={9}>
                <CityStatisticsCard selectedCity={selectedCity} cellStyle={cellStyle} />
              </Col>

              {selectedRowKeys.length > 0 && (
                <Space style={{ marginBottom: 2, display: "flex", justifyContent: "flex-end" }}>
                  {isAdmin && (
                    <Button type="primary" icon={<SendOutlined />} onClick={handleSaveSelected} loading={dataLoading}>
                      {t("finance.invoices.sendToRecipient")}
                    </Button>
                  )}
                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() =>
                      Modal.confirm({
                        title: t("common.delete"),
                        icon: <ExclamationCircleOutlined />,
                        okButtonProps: { danger: true },
                        onOk: () => {
                          handleMassDelete();
                        },
                      })
                    }
                  >
                    {t("common.delete")}
                  </Button>
                </Space>
              )}
              <Table
                size="small"
                loading={dataLoading}
                columns={tableColumns}
                title={() => <h4>{t("finance.transactions.paymentsForApprovalTransactionsCount") + ": " + paginationDetails?.total || 0}</h4>}
                dataSource={transactions}
                rowSelection={rowSelection}
                pagination={{
                  current: paginationDetails?.current_page,
                  pageSize: paginationDetails?.perPage,
                  total: paginationDetails?.total,
                  page: currentPage,
                  showSizeChanger: true,
                  onChange: (e) => {
                    setCurrentPage(e);
                  },
                  showTotal: (total, range) => (
                    <p>
                      {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
                      {" " + t("common.paginationPartTwo") + " "}
                      <strong>{total}</strong> {t("common.paginationPartThree")}
                    </p>
                  ),
                }}
                onChange={(pagination, filters, sorter) => {
                  setSorter(sorter);
                  setFilters({
                    perPage: pagination?.pageSize,
                  });
                }}
                rowKey={"id"}
              />
            </>
          )}
        </React.Fragment>
      </Tabs.TabPane>
      <Tabs.TabPane tab={t("finance.paymentsForApproval.fillPartnerAccountsTab")} key="3">
        <CityAndBankAccountsOverviewPane />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default PaymentsForApprovalPane;
